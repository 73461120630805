import {Grid, TextField, Button, Container, Zoom, Typography} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CALL_LOGIN, DASHBOARD } from "./config/routes/Routes";
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Countdown from './components/Countdown';
import { useDispatch } from 'react-redux'
import { setCredentials } from "./features/auth/authSlice";
import { Link } from 'react-router-dom'

function Login() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [attemptsExceeded, setAttemptsExceeded] = useState(0);
  const [mostrarModal, setMostrarModal] = useState(false);
  
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    if (attemptsExceeded === 3) {
      setMostrarModal(true);
      setAttemptsExceeded(0);
    }
  }, [attemptsExceeded]);

  const handleFinish = () => {
    setMostrarModal(false);
  }

  const checkCredentials = async () => {
    return axios.post(CALL_LOGIN, { username, password })
    .then(response => { return response.data.accessToken; })
    .catch(err => {return null});
  }

  const handleSubmit = async() => {
      const token = await checkCredentials()
      if (token){
        localStorage.setItem("token", token);
        axios.defaults.headers.common['access-token'] = token;
        dispatch(setCredentials({ token }));
        navigate(DASHBOARD);
      } else {
        setAttemptsExceeded(attemptsExceeded + 1);
        setInvalidPassword(true)
      }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  };

  return (
    <Container>
      <Zoom in={true} style={{ transitionDelay: "200ms" }}>
        <Container maxWidth="sm" className="formLogin" sx={{padding:"1rem"}}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography  variant="h4" sx={{fontFamily: 'Rubik', fontWeight: "600"}}>Iniciar Sesión</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="rounded"
                error={invalidPassword}
                type="email"
                id="username"
                label="Usuario"
                variant="outlined"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value)
                  setInvalidPassword(false)
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                className="rounded"
                error={invalidPassword}
                id="outlined-password-input"
                label="Contraseña"
                type="password"
                autoComplete="current-password"
                value={password}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setPassword(e.target.value)
                  setInvalidPassword(false)
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="caption" className="font-black text-red-800" hidden={invalidPassword ? "" : "hidden"}>Tus credenciales son erróneas</Typography>
            </Grid>

            {/* ToDo Descomentar cuando se resuelva el envio del correo de recuperacion de contraseña */}
            {/*<Grid item xs={12}>
              <Link to="/forgottenPassword">¿Olvidaste la contraseña?</Link>
              </Grid>*/}

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                size="medium"
                onClick={handleSubmit}
                sx={{fontFamily: "Rubik"}}
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Zoom>
      <Modal open={mostrarModal}>
        <Grid container spacing={2} textAlign="center" className="attemptModal"
              sx={{marginLeft:'20%',
              marginTop:'20%',
              padding:'2rem'}}>
          <Grid item xs={12} marginTop="1px">
            <Typography sx={{fontFamily: "Rubik"}} variant="h5" className="font-black">Número de intentos excedidos</Typography>
          </Grid>
          <Grid item xs={12}>
            <Countdown onFinish={handleFinish} />
          </Grid>
        </Grid>
      </Modal>
    </Container>
  );
}

export default Login;
