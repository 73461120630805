import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import RoutesTree from "./config/routes/RoutesTree";
import { ParamProvider } from "./components/ParamContext";


const theme = createTheme({
  typography: {
    primary: {
      color: "#05000D"
    }
  },
  palette: {
    background: { default: "#F2F2F2", secondary: "#FFFFFF" },
    primary: {
      main: "#05000D"
    },
    secondary: {
      main: "rgba(160, 25, 26, 1)"
    }
  },
});

function App() {
  return (
    <div className="App">
      <ParamProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RoutesTree />
        </ThemeProvider>
      </ParamProvider>
    </div>
  );
}

export default App;
