import {
  Modal,
  Button,
  Container,
  TextField,
  Typography,
  Snackbar,
  Alert, Grid
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { CALL_ROLE_GENERIC, CALL_PRIVILEGES } from "../config/routes/Routes";
import ComboNestedCheckbox from "./ComboNestedCheckbox";

function ManageRoleWindow({ editing, deleting, data, onClose }) {
  const [showErrorSnackBar, setShowErrorSnackBar] = useState({});
  const [privilegesList, setPrivilegesList] = useState([]);
  const [childPrivilegesList, setChildPrivilegesList] = useState([]);
  const [rolePrivileges, setRolePrivileges] = useState([]);
  const [invalidRoleName, setInvalidRoleName] = useState();

  useEffect(() => {
    getAllPrivileges();
  }, []);

  const [formData, setFormData] = useState({
    id: data.id,
    name: data.name,
    privileges: data.privileges,
  });

  function getAllPrivileges() {
    axios
      .get(CALL_PRIVILEGES)
      .then((response) => {
        let privileges = [];
        let childPrivileges = [];
        response.data.forEach((option) => {
          let parsedOption = {
            id: option.id,
            name: option.name,
            father: option.father,
            checked: false,
            expanded: false,
          };
          if (!option.father) privileges.push(parsedOption);
          else childPrivileges.push(parsedOption);
        });
        setPrivilegesList(privileges);
        setChildPrivilegesList(childPrivileges);
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const handleInputChange = (event) => {
    setInvalidRoleName(false);
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePrivilegesChange = (options) => {
    setRolePrivileges(options);
    formData.privileges = options;
  };

  function isValidForm(data) {
    setInvalidRoleName(data.name == "");
    return data.name !== "";
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (rolePrivileges.length !== 0) formData.privileges = rolePrivileges;
    if (isValidForm(formData)) {
      if (formData.id) {
        axios
          .put(CALL_ROLE_GENERIC, formData)
          .then((response) => {
            onClose(true);
          })
          .catch((error) => {
            onClose(false);
            error.handleGlobally && error.handleGlobally();
          });
      } else {
        axios
          .post(CALL_ROLE_GENERIC, formData)
          .then((response) => {
            onClose(true);
          })
          .catch((error) => {
            onClose(false);
            error.handleGlobally && error.handleGlobally();
          });
      }
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    axios
      .delete(CALL_ROLE_GENERIC, { data: { id: formData.id } })
      .then((response) => {
        onClose(true);
      })
      .catch((error) => {
        onClose(false);
        error.handleGlobally && error.handleGlobally();
      });
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Container
        className="bg-white flex-col justify-around relative rounded-lg p-4 top-1/4"
        sx={{ width: "fit-content", display: "flex" }}
      >
        {editing && (
          <>
            <Typography variant="h5" sx={{fontFamily: "Rubik", fontWeight: "600", margin: "1rem 0"}}>
              {data.name ? "Editar Rol" : "Añadir Rol"}
            </Typography>
            <Container>
              <Container className="mb-4">
                <TextField
                  label="Nombre"
                  variant="outlined"
                  name="name"
                  sx={{width: "100%"}}
                  error={invalidRoleName}
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <Grid item xs={12}>
                <Typography
                    variant="caption"
                    className="font-black text-red-800"
                    hidden={invalidRoleName ? "" : "hidden"}
                >
                  Nombre es un campo obligatorio
                </Typography>
              </Grid>
              </Container>
              <Container className="mb-4">
                <ComboNestedCheckbox
                  comboIdName="comboPrivilegios"
                  fatherOptions={privilegesList}
                  childOptions={childPrivilegesList}
                  style={{ width: "325px" }}
                  label="Privilegios"
                  inputValues={formData.privileges}
                  onChange={handlePrivilegesChange}
                ></ComboNestedCheckbox>
              </Container>
            </Container>
            {showErrorSnackBar.status && (
              <Snackbar
                open={showErrorSnackBar.status}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={3000}
                onClose={() =>
                  setShowErrorSnackBar({ status: false, message: "" })
                }
                disableWindowBlurListener
              >
                <Alert severity="error" variant="filled">
                  {showErrorSnackBar.message}
                </Alert>
              </Snackbar>
            )}
          </>
        )}
        {deleting && (
          <Typography variant="h5">
            ¿Desea eliminar el rol {formData.name}?
          </Typography>
        )}
        <Container sx={{ display: "flex", justifyContent: "space-around", margin: "1rem 0" }}>
          <Button
            color="primary"
            sx={{ fontFamily: "Rubik" }}
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontFamily: "Rubik" }}
            onClick={deleting ? handleDelete : handleSubmit}
          >
            {deleting ? "Aceptar" : "Guardar"}
          </Button>
        </Container>
      </Container>
    </Modal>
  );
}

export default ManageRoleWindow;
