import {
  Modal,
  Button,
  Container,
  TextField,
  Typography
} from "@mui/material";
import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import axios from "axios";
import dayjs from "dayjs";
import { CALL_INCIDENCE } from "../config/routes/Routes";

function CreateIncidentWindow({ data, onClose }) {
  const [formData, setFormData] = useState({
    signingId: data.id,
    tipo: data.actionId,
    incidenceDate: dayjs(data.date),
    incidenceDescription: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleIncidenceDateChange = (nwDate) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      incidenceDate: nwDate,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(CALL_INCIDENCE, formData)
      .then((response) => {
        onClose(true);
      })
      .catch((error) => {
        onClose(false);
        error.handleGlobally && error.handleGlobally();
      });
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Container
        className=" bg-white flex-col justify-around relative rounded-lg p-4 top-1/4 h-2/4 flex"
        sx={{ width: "fit-content", display: "flex" }}
      >
        <Typography variant="h5" sx={{fontFamily: "Rubik", fontWeight: "600"}}>Crear Incidencia</Typography>
        <Container>
          <Container className="mb-4">
            <TextField
              label="Tipo"
              variant="outlined"
              name="tipo"
              value={formData.tipo}
              onChange={handleInputChange}
              InputProps={{
                readOnly: true,
              }}
            />
          </Container>

          <Container className="mb-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                label="Fecha y Hora"
                name="incidenceDate"
                value={formData.hora}
                onChange={handleIncidenceDateChange}
                format="DD/MM/YYYY hh:mm A"
              />
            </LocalizationProvider>
          </Container>

          <Container>
            <TextField
              fullWidth
              label="Descripción"
              multiline
              inputProps={{ maxLength: 140 }}
              name="incidenceDescription"
              value={formData.descripcion}
              onChange={handleInputChange}
            />
          </Container>
        </Container>

        <Container sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button color="primary" sx={{fontFamily: "Rubik"}} onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" sx={{fontFamily: "Rubik"}} onClick={handleSubmit}>
            Guardar
          </Button>
        </Container>
      </Container>
    </Modal>
  );
}

export default CreateIncidentWindow;
