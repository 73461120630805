import {
  Container,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { esES } from "@mui/x-data-grid";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import dayjs from "dayjs";
import StyledDataGrid from "./StyledDataGrid";
import CustomPagination from "../CustomPagination";
import IncidenceMoreDataWindow from "./IncidenceMoreDataWindow";
import {
  CALL_REPORT,
  CALL_SERVICES,
  CALL_USER_DATA,
  CALL_USER_INCIDENCES,
} from "../config/routes/Routes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";

function MyIncidencesDashboard({ display, refresh, setRefresh }) {
  const [elemlist, setElemlist] = useState([]);
  const [rows, setRows] = useState([]);
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;

  const canPdfIncidencias = userPrivileges.includes(Privileges.G_INC_PDF_INC);

  function openMoreDataWindow(params) {
    setSelectedData(params.row);
    setIsWindowOpen(true);
  }

  function closeMoreDataWindow() {
    setIsWindowOpen(false);
  }

  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    getIncidences();
    moment().locale("es-ES");
  }, [refresh]);

  const handleInitialDate = (nwDate) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: nwDate,
    }));
  };

  const handleFinalDate = (nwDate) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: nwDate,
    }));
  };

  const getElemList = () =>
    elemlist.map((e, i) =>
      setRows(rows.push({ id: i, col1: e.actionId, col2: e.date }))
    );

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "signingId", headerName: "Fichaje ID", flex: 1 },
    {
      field: "validationId",
      headerName: "Validación",
      flex: 1,
      renderCell: (params) => {
        switch (params.row.validationId) {
          case 1:
            return <Typography>Pendiente</Typography>;
          case 2:
            return <Typography>Aceptada</Typography>;
          case 3:
            return <Typography>Rechazada</Typography>;
          default:
            return <Typography>Sin Estado</Typography>;
        }
      },
    },
    {
      field: "date",
      headerName: "Fecha",
      flex: 2,
      renderCell: (params) => (
        <Typography>
          {dayjs(params.value).format("DD/MM/YYYY HH:mm")}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Tooltip title="Ver más">
            <span>
              <IconButton
                size="small"
                disabled={isWindowOpen ? true : false}
                onClick={() => openMoreDataWindow(params)}
              >
                <ReadMoreIcon />
              </IconButton>
            </span>
          </Tooltip>
        </strong>
      ),
    },
  ];

  function formatDataForMySQL(date) {
    let day = String(date.date()).padStart(2, "0");
    let month = String(date.month() + 1).padStart(2, "0");
    let year = date.year();
    return `${year}-${month}-${day}`;
  }

  const descargaPDF = () => {
    let startDate = formData.startDate;
    let endDate = formData.endDate;

    if (startDate == null && endDate == null) {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      startDate = thirtyDaysAgo;
      endDate = new Date();
    } else {
      startDate = formatDataForMySQL(startDate);
      endDate = formatDataForMySQL(endDate);
    }

    axios
      .get(CALL_USER_DATA)
      .then((response) => {
        let user = { id: response.data.id, name: response.data.name };

        axios.get(CALL_SERVICES).then((services) => {
          let service = services.data.filter(
            (s) => s.id === response.data.serviceId
          )[0];

          let format = { id: 1, name: "PDF" };
          let params = { user, service, startDate, endDate, format };

          axios
            .post(CALL_REPORT, params, { responseType: "blob" })
            .then((response) => {
              let blob = new Blob([response.data], { type: "application/pdf" });
              let url = URL.createObjectURL(blob);
              let link = document.createElement("a");
              link.href = url;
              link.download = "report.pdf";
              link.click();
              URL.revokeObjectURL(url);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((error) => {});
  };

  function aplicaFiltros() {
    getIncidences();
  }

  function getIncidences() {
    axios
      .get(CALL_USER_INCIDENCES, {
        params: formData,
      })
      .then((response) => {
        setElemlist(response.data);
        getElemList();
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  return (
    <Container sx={{ height: "70vh" }}>
      <Grid container spacing={1} sx={{ marginBottom: "1rem" }}>
        <Grid item sm={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha Inicial"
              id="datepicker1"
              onChange={handleInitialDate}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha Final"
              id="datepicker2"
              minDate={formData.startDate}
              onChange={handleFinalDate}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={3}>
          <Tooltip title="Aplicar filtros">
            <Button
              sx={{
                backgroundColor: "#05000D",
                color: "white",
                "&:hover": {
                  backgroundColor: "#16111E",
                },
                height: "100%",
              }}
              onClick={aplicaFiltros}
            >
              <SearchIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={12} md={1} sx={{ textAlign: "right" }}>
        {canPdfIncidencias && (
          <Tooltip title="Descarga PDF">
            <Button
              sx={{
                backgroundColor: "#05000D",
                color: "white",
                "&:hover": {
                  backgroundColor: "#16111E",
                },
                height: "100%",
              }}
              onClick={descargaPDF}
            >
              <PictureAsPdfIcon />
            </Button>
          </Tooltip>
            )}
        </Grid>
      </Grid>
      {display && (
        <StyledDataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={elemlist}
          columns={columns}
          slots={{
            pagination: CustomPagination,
          }}
        ></StyledDataGrid>
      )}
      {isWindowOpen && (
        <IncidenceMoreDataWindow
          data={selectedData}
          onClose={closeMoreDataWindow}
        />
      )}
    </Container>
  );
}

export default MyIncidencesDashboard;
