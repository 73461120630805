import {
  Container,
  Typography,
  IconButton,
  Button,
  Grid,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Block";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import axios from "axios";
import moment from "moment";

import { GridToolbar, esES } from "@mui/x-data-grid";
import { CALL_USERS } from "../config/routes/Routes";
import AccountSettingsDashboard from "./AccountSettingsDashboard";
import EnableDisableUserWindow from "./EnableDisableUserWindow";
import StyledDataGrid from "./StyledDataGrid";
import CustomPagination from "../CustomPagination";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";

function UserManagerDashboard() {
  const [elemlist, setElemlist] = useState([]);
  const [rows, setRows] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [reFetchUsers, setRefetchUsers] = useState(true);
  const [selectedData, setSelectedData] = useState(null);
  const [addEditUserOpen, setAddEditUserOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});
  const [modalOpen, setModalOpen] = useState(false);

  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const isCreateEnabled = userPrivileges.includes(Privileges.G_USU_ADD);
  const isEditEnabled = userPrivileges.includes(Privileges.G_USU_UPD);
  const canEnableDisable = userPrivileges.includes(Privileges.G_USU_HAB);

  moment().locale("es-ES");

  useEffect(() => {
    getUsers();
  }, [reFetchUsers]);

  function getUsers() {
    axios
      .get(CALL_USERS)
      .then((response) => {
        setElemlist(response.data);
        getElemList();
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const getElemList = () => {
    elemlist.map((e, i) =>
      setRows(
        rows.push({
          id: e.id,
          name: e.name,
          surname: e.surname,
          email: e.email,
          phone: e.phone,
        })
      )
    );
  };

  function onAddNewUser() {
    setSelectedData({
      id: "",
      docId: "",
      name: "",
      surnames: "",
      email: "",
      phone: "",
      address: "",
      serviceId: "",
      roleId: "",
    });
    setIsEditing(!isEditing);
    setAddEditUserOpen(true);
  }

  function onEditUserRow(params) {
    setSelectedData(params.row);
    setIsEditing(!isEditing);
    setAddEditUserOpen(true);
  }

  function onInvalidateUser(params) {
    setSelectedData(params.row);
    setIsEditing(!isEditing);
    setModalOpen(true);
  }

  function onEnableUser(params) {
    setSelectedData(params.row);
    setIsEditing(!isEditing);
    setModalOpen(true);
  }

  const onExitAddUserEdit = (success) => {
    setRefetchUsers(!reFetchUsers);
    setIsEditing(!isEditing);
    setAddEditUserOpen(false);
    handleSnackbarOpen(success)
  };

  const onCloseModal = (success) => {
    setRefetchUsers(!reFetchUsers);
    setIsEditing(!isEditing);
    setModalOpen(false);
    handleSnackbarOpen(success)
  };

  const handleSnackbarOpen = (success) => {
    let isSuccess = !(typeof(success) !== "boolean" || !success)
    let message  = isSuccess ? "Datos modificados correctamente!" : "No se han registrado cambios!"
    let severity = isSuccess ? "success" : "error";
    setSnackbarObject({message: message, severity: severity});
    setSnackbarOpen(true);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "docId", headerName: "Documento de Identidad", flex: 1.3 },
    { field: "name", headerName: "Nombre", flex: 1 },
    { field: "surnames", headerName: "Apellidos", flex: 1.5 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Núm. teléfono", flex: 1 },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 0.5,
      renderCell: (params) => (
        <strong>
          {!params.row.disabled && (
            <Tooltip title="Editar usuario">
              <span>
                <IconButton
                  size="small"
                  disabled={isEditEnabled ? (isEditing ? true : false) : true}
                  onClick={() => onEditUserRow(params)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {!params.row.disabled && (
            <Tooltip title="Deshabilitar usuario">
              <span>
                <IconButton
                  size="small"
                  disabled={
                    canEnableDisable ? (isEditing ? true : false) : true
                  }
                  onClick={() => onInvalidateUser(params)}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {params.row.disabled && (
            <Tooltip title="Habilitar usuario">
              <span>
                <IconButton
                  size="small"
                  disabled={
                    canEnableDisable ? (isEditing ? true : false) : true
                  }
                  onClick={() => onEnableUser(params)}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </strong>
      ),
    },
  ];

  return (
    <Container sx={{ height: "70vh" }}>
      {!addEditUserOpen && (
        <>
          <Grid container spacing={2}>
            <Grid item sm={12} md={11}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Rubik",
                  fontWeight: "600",
                  paddingBottom: "10px",
                }}
              >
                Usuarios registrados
              </Typography>
            </Grid>
            <Grid item sm={12} md={1} sx={{ textAlign: "right" }}>
              {isCreateEnabled && (
                <Tooltip title="Crear usuario">
                  <Button
                    sx={{
                      marginBottom: "1rem",
                      backgroundColor: "#05000D",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#16111E",
                      },
                    }}
                    onClick={onAddNewUser}
                  >
                    <PersonAddAlt1Icon />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <StyledDataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={elemlist}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            slots={{
              pagination: CustomPagination,
            }}
            getRowClassName={(params) => {
              return params.row.disabled ? "disabled" : "";
            }}
          ></StyledDataGrid>
        </>
      )}

      {addEditUserOpen && (
        <Container
          className="flex-col justify-around relative rounded-lg p-4"
          sx={{ width: "fit-content", display: "flex" }}
        >
          <AccountSettingsDashboard
            editing={isEditing}
            data={selectedData}
            onClose={onExitAddUserEdit}
          />
        </Container>
      )}

      {modalOpen && !addEditUserOpen && (
        <EnableDisableUserWindow data={selectedData} onClose={onCloseModal} />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        disableWindowBlurListener
      >
        <Alert
          severity={snackbarObject.severity}
          sx={{ width: "100%" }}
        >
          {snackbarObject.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default UserManagerDashboard;
