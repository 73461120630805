import {
  Modal,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  CALL_CARD_GENERIC,
  CALL_USERS,
} from "../config/routes/Routes";

function ManageCardWindow({ editing, data, onClose }) {
  const [usersList, setUsersList] = useState([]);
  const [invalidCardCode, setInvalidCardCode] = useState();
  const [invalidCardUser, setInvalidCardUser] = useState();

  useEffect(() => {
    getUsers();
  }, []);

  const [formData, setFormData] = useState({
    id: data.id,
    value: data.value,
    userId: data.userId,
    userName: data.userName,
    statusId: data.statusId,
  });

  function getUsers() {
    axios
      .get(CALL_USERS)
      .then((response) => {
        let users = [];
        response.data.forEach((option) => {
          let parsedOption = {
            id: option.id,
            label: option.name + " " + option.surnames,
          };
          users.push(parsedOption);
        });

        setUsersList(users);
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const handleInputChange = (event) => {
    setInvalidCardCode(false);
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUserChange = (event, newValue) => {
      setInvalidCardUser(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: newValue.id,
        userName: newValue.label,
      }));
  };

  const handleStatusChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      statusId: event.target.checked ? 1 : 2,
    }));
  };

  function isValidForm(data) {
    setInvalidCardCode(data.value === "");
    setInvalidCardUser(data.userId === -1);
    return data.value !== "" && data.userId !== -1;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidForm(formData)) {
      if (formData.id !== -1) {
        axios
          .put(CALL_CARD_GENERIC, formData)
          .then((response) => {
            onClose(true);
          })
          .catch((error) => {
            onClose(false);
            error.handleGlobally && error.handleGlobally();
          });
      } else {
        axios
          .post(CALL_CARD_GENERIC, formData)
          .then((response) => {
            onClose(true);
          })
          .catch((error) => {
            onClose(false);
            error.handleGlobally && error.handleGlobally();
          });
      }
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Container
        className="bg-white flex-col justify-around relative rounded-lg p-4 top-1/4"
        sx={{ width: "fit-content", display: "flex" }}
      >
        {editing && (
          <>
            <Typography
              variant="h5"
              sx={{ fontFamily: "Rubik", fontWeight: "600", margin: "1rem 0" }}
            >
              {data.id !== -1 ? "Editar Tarjeta" : "Añadir Tarjeta"}
            </Typography>
            <Container>
              <Container className="mb-4">
                <TextField
                  label="Código de Tarjeta"
                  variant="outlined"
                  name="value"
                  sx={{ width: "100%" }}
                  error={invalidCardCode}
                  value={formData.value}
                  onChange={handleInputChange}
                />
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    className="font-black text-red-800"
                    hidden={invalidCardCode ? "" : "hidden"}
                  >
                    El código de la tarjeta es un campo obligatorio
                  </Typography>
                </Grid>
              </Container>
              <Container className="mb-4">
                <Autocomplete
                  value={formData.userName}
                  disablePortal
                  disableClearable={true}
                  options={usersList}
                  isOptionEqualToValue={(option, value) => option.label === value || value === ""}
                  style={{ width: "325px" }}
                  onChange={handleUserChange}
                  selectOnFocus
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Usuario"
                      error={invalidCardUser}
                    />
                  )}
                />
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    className="font-black text-red-800"
                    hidden={invalidCardUser ? "" : "hidden"}
                  >
                    El usuario es un campo obligatorio
                    </Typography>  
                </Grid>
              </Container>
              <Container className="mb-4">
                <FormControlLabel
                  label="Activa"
                  control={
                    <Checkbox
                      checked={formData.statusId === 1}
                      onChange={handleStatusChange}
                      name="status"
                    />
                  }
                />
              </Container>
            </Container>
          </>
        )}
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-around",
            margin: "1rem 0",
          }}
        >
          <Button
            color="primary"
            sx={{ fontFamily: "Rubik" }}
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontFamily: "Rubik" }}
            onClick={handleSubmit}
          >
            {"Guardar"}
          </Button>
        </Container>
      </Container>
    </Modal>
  );
}

export default ManageCardWindow;
