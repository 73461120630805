import { Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import NotFoundComponent from "../../components/default/NotFoundComponent";
import ForbiddenPageComponent from "../../components/default/ForbiddenPageComponent";

const RequireAuth = ({ allowedPrivileges }) => {
    const userInfo = useAuth();

    const privileges = userInfo.privileges;

    const content = (
        privileges.some(privilege => allowedPrivileges.includes(privilege))
            ? <Outlet />
            : <ForbiddenPageComponent />
    )
    return content
}
export default RequireAuth