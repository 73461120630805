import { Container } from "@mui/material";
import React from "react";
import { esES, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import StyledDataGrid from "./StyledDataGrid";
import CustomPagination from "../CustomPagination";

function ReportSigningsDashboard({ rows, display }) {
  const columns = [
    {
      field: "date",
      headerName: "Fecha",
      flex: 1.5,
      renderCell: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY HH:mm");
      },
    },
    { field: "email", headerName: "Email", flex: 1.5 },
    { field: "mode", headerName: "Desde", flex: 1 },
    {
      field: "actionId",
      headerName: "Acción",
      flex: 1,
      renderCell: (params) => {
        return params.value === 1 ? "Entrada" : "Salida";
      },
    },
    {
      field: "incidenceId",
      headerName: "Incidencia",
      flex: 1,
      renderCell: (params) => {
        return params.value != null ? "Sí" : "No";
      },
    },
    {
      field: "validationId",
      headerName: "Validación",
      flex: 1,
      renderCell: (params) => {
        switch (params.row.validationId) {
          case 1:
            return "Pendiente";
          case 2:
            return "Aceptada";
          case 3:
            return "Rechazada";
          default:
            return "Sin Estado";
        }
      },
    },
    { field: "description", headerName: "Descripción", flex: 2 },
    { field: "reply", headerName: "Respuesta", flex: 2 },
  ];

  return (
    <Container sx={{ height: "70vh" }}>
      {display && (
        <StyledDataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          slots={{ pagination: CustomPagination }}
          getRowId={(row) => row.id}
        ></StyledDataGrid>
      )}
    </Container>
  );
}

export default ReportSigningsDashboard;
