import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .disabled": {
      backgroundColor: "transparent",
      "& .MuiDataGrid-cell": {
        color: "gray",
      },
      "&:hover, &.Mui-hovered": {
        backgroundColor: "transparent",
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: "transparent",
        "& .MuiDataGrid-cell": {
          color: "gray",
        },
        "&:hover, &.Mui-hovered": {
          backgroundColor: "transparent",
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    backgroundColor: "transparent",
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      width: "0.4em",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      borderRadius: 5,
      backgroundColor: "#e5e7eb",
    },
    color: "black",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid 1d1d1d`,
    },
    "& .MuiDataGrid-cell": {
      color: "black",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    }
  }));

  export default StyledDataGrid;