import {
  Container,
  Typography,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Grid,
  Button,
} from "@mui/material";
import { GridToolbar, esES } from "@mui/x-data-grid";
import React from "react";
import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import ManageIncidenceWindow from "./ManageIncidenceWindow";
import CustomPagination from "../CustomPagination";
import StyledDataGrid from "./StyledDataGrid";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";
import { CALL_INCIDENCES } from "../config/routes/Routes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";

function IncidenceRequestsMGDashboard({ display, refresh, setRefresh }) {
  const [elemlist, setElemlist] = useState([]);
  const [rows, setRows] = useState([]);

  //Variables para el modal
  const [selectedData, setSelectedData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});

  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const canSeeAllIncidences = userPrivileges.includes(Privileges.G_INC_INC_ALL);
  const canResolveIncidence = userPrivileges.includes(Privileges.G_INC_RSLV);

  const getElemList = () =>
    elemlist.map((e, i) =>
      setRows(rows.push({ id: i, col1: e.actionId, col2: e.date }))
    );

  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
  });

  const handleInitialDate = (nwDate) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: nwDate,
    }));
  };

  const handleFinalDate = (nwDate) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: nwDate,
    }));
  };

  function onEditIncidencesRow(params) {
    //Abrir Ventana Modificar
    setSelectedData(params.row);
    setModalOpen(true);
  }

  useEffect(() => {
    if (canSeeAllIncidences) {
      getIncidences();
      moment().locale("es-ES");
    }
  }, [refresh]);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "signingId", headerName: "Id Fichaje", flex: 1 },
    {
      field: "validationId",
      headerName: "Estado",
      flex: 1,
      renderCell: (params) => {
        switch (params.row.validationId) {
          case 1:
            return <Typography sx={{ fontSize: "1em" }}>Pendiente</Typography>;
          case 2:
            return <Typography sx={{ fontSize: "1em" }}>Aceptada</Typography>;
          case 3:
            return <Typography sx={{ fontSize: "1em" }}>Rechazada</Typography>;
          default:
            return <Typography sx={{ fontSize: "1em" }}>Sin Estado</Typography>;
        }
      },
    },
    {
      field: "date",
      headerName: "Fecha",
      flex: 2,
      renderCell: (params) => (
        <Typography sx={{ fontSize: "1em" }}>
          {dayjs(params.value).format("DD/MM/YYYY HH:mm")}
        </Typography>
      ),
    },
    { field: "description", headerName: "Descripción", flex: 3 },
    { field: "reply", headerName: "Motivo", flex: 2 },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => {
        if (params.row.validationId == 1) {
          return (
            <strong>
              <Tooltip title="Resolver incidencia">
                <span>
                  <IconButton
                    size="small"
                    disabled={!canResolveIncidence}
                    onClick={() => onEditIncidencesRow(params)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </strong>
          );
        }
      },
    },
  ];

  function aplicaFiltros() {
    canSeeAllIncidences && getIncidences();
  }

  function getIncidences() {
    axios
      .get(CALL_INCIDENCES, {
        params: formData,
      })
      .then((response) => {
        response.data.forEach((element, i) => {
          element.actionId == 1
            ? (element.actionId = "Entrada")
            : (element.actionId = "Salida");
        });

        setElemlist(response.data);
        getElemList();
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const onCloseModal = (success) => {
    setModalOpen(false);
    let isSuccess = !(typeof(success) !== "boolean" || !success)
    let message  = isSuccess ? "Cambios guardados correctamente!" : "No se han registrado cambios!";
    let severity = isSuccess ? "success" : "error";
    setSnackbarObject({message: message, severity: severity});
    setSnackbarOpen(true);
    setRefresh(!refresh);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <Container sx={{ height: "70vh" }}>
      <Grid container spacing={1} sx={{ marginBottom: "1rem" }}>
        <Grid item sm={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha Inicial"
              id="datepicker1"
              onChange={handleInitialDate}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha Final"
              id="datepicker2"
              minDate={formData.startDate}
              onChange={handleFinalDate}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={1}>
          <Tooltip title="Aplicar filtros">
            <Button
              sx={{
                backgroundColor: "#05000D",
                color: "white",
                "&:hover": {
                  backgroundColor: "#16111E",
                },
                height: "100%",
              }}
              onClick={aplicaFiltros}
            >
              <SearchIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {display && (
        <StyledDataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={elemlist}
          columns={columns}
          columnVisibilityModel={{ id: false }}
          components={{
            Toolbar: GridToolbar,
          }}
          slots={{
            pagination: CustomPagination,
          }}
        ></StyledDataGrid>
      )}
      {modalOpen && (
        <ManageIncidenceWindow data={selectedData} onClose={onCloseModal} />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        disableWindowBlurListener
      >
        <Alert
          severity={snackbarObject.severity}
          sx={{ width: "100%" }}
        >
          {snackbarObject.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default IncidenceRequestsMGDashboard;
