import {
  Modal,
  Button,
  Container,
  TextField,
  Typography, Grid
} from "@mui/material";
  import React, { useState } from "react";
  import axios from "axios";
  import { CALL_EDITSERVICE } from "../config/routes/Routes";
  
  function CreateServiceWindow({ onClose }) {
    const [formData, setFormData] = useState({
      serviceId: "",
      serviceName: "",
    });
    const [invalidServiceName, setInvalidServiceName] = useState(false);

    const handleInputChange = (event) => {
      setInvalidServiceName(false);

      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };

    function validateServiceName(serviceName) {
      return serviceName.trim() !== '';
    }

    function validateForm(data) {
      let valid = true;

      if (!validateServiceName(data.serviceName)) {
        valid = false;
        setInvalidServiceName(true);
      }

      return valid;
    }
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm(formData)) {
        axios
            .post(CALL_EDITSERVICE, formData)
            .then((response) => {
              onClose(true);
            })
            .catch((error) => {
              onClose(false);
              error.handleGlobally && error.handleGlobally();
            });
      }
    };
  
    return (
      <Modal open={true} onClose={onClose}>
        <Container
          className=" bg-white flex-col justify-around relative rounded-lg p-4 top-1/4 h-1/4 shadow-lg"
          sx={{ width: "fit-content", display: "flex" }}
        >
          <Typography variant="h5" sx={{fontFamily: 'Rubik', fontWeight: "600"}}>Crear Departamento</Typography>
          <Container>  
            <Container>
              <TextField
                fullWidth
                label="Nombre"
                multiline
                inputProps={{ maxLength: 50 }}
                name="serviceName"
                error={invalidServiceName}
                value={formData.serviceName}
                onChange={handleInputChange}
              />
              <Grid item xs={12}>
                <Typography
                    variant="caption"
                    className="font-black text-red-800"
                    hidden={invalidServiceName ? "" : "hidden"}
                >
                  Nombre es un campo obligatorio
                </Typography>
              </Grid>
            </Container>
          </Container>
          <Container sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button color="primary" sx={{fontFamily: "Rubik"}} onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" sx={{fontFamily: "Rubik"}} onClick={handleSubmit}>
              Guardar
            </Button>
          </Container>
        </Container>
      </Modal>
    );
  }
  
  export default CreateServiceWindow;
  