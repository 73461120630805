import { Container, Tabs, Tab, Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ReportManagerDashboard from "./ReportManagerDashboard";
import ReportSigningsDashboard from "./ReportSigningsDashboard";
import { styled } from "@mui/material/styles";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontFamily: "Rubik",
    fontWeight: "600",
  })
);

function ReportDashboard() {
  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const isCreateEnabled = userPrivileges.includes(Privileges.G_REP_CREATE);
  const canSeeSignings = userPrivileges.includes(Privileges.G_REP_FICH);

  const defaultValue = isCreateEnabled ? 0 : 1;
  const [value, setValue] = React.useState(defaultValue);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [filteredTabActive, setFilteredTabActive] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ height: "80vh" }}>
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {isCreateEnabled && (
            <StyledTab label="Gestión de Reports" {...a11yProps(0)} value={0} />
          )}
          {canSeeSignings && filteredTabActive && (
            <StyledTab label="Fichajes filtrados" {...a11yProps(1)} value={1} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ReportManagerDashboard
          setFilteredRows={setFilteredRows}
          setFilteredTabActive={setFilteredTabActive}
          changeActiveTab={setValue}
        />
      </TabPanel>
      {filteredTabActive && (
        <TabPanel value={value} index={1}>
          <ReportSigningsDashboard rows={filteredRows} display={value === 1}/>
        </TabPanel>
      )}
    </Container>
  );
}

export default ReportDashboard;
