import React, { useState, useEffect } from 'react';
import {Typography} from "@mui/material";

const Countdown = ({ onFinish }) => {
  const [count, setCount] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    if (count === 0) {
      clearInterval(interval);
      onFinish();
    }

    return () => {
      clearInterval(interval);
    };
  }, [count, onFinish]);

  return <Typography sx={{fontFamily: "Rubik"}} variant="body1" className="font-black">{count}s. hasta el próximo intento</Typography>
};

export default Countdown;