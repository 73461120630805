import { Outlet, Link, useNavigate } from "react-router-dom";
import { LOGIN } from "../../config/routes/Routes";
import { useEffect, useRef, useState } from "react";
import { useRefreshMutation } from "./authApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";
import axios from "axios";
import { Container, Typography, Button, CircularProgress } from "@mui/material";
import unauthorized from "../../resources/unauthorized.png";

const PersistLogin = () => {
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);

  const [trueSuccess, setTrueSuccess] = useState(false);

  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
        setTrueSuccess(true);
      } catch (err) {}
    };

    if (!token) verifyRefreshToken();
    return () => (effectRan.current = true);
  }, []);

  const navigate = useNavigate();

  const errorComposer = (error) => {
    return () => {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 403 || statusCode === 401) {
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["access-token"];
        navigate(LOGIN);
      }
    };
  };

  axios.interceptors.response.use(undefined, function (error) {
    error.handleGlobally = errorComposer(error);
    return Promise.reject(error);
  });

  let content;
  if (isLoading) {
    content = (<Container sx={{textAlign: "center"}}><CircularProgress/></Container>);
  } else if (isError) {
    content = (
      <Container>
        <Container
          maxWidth="sm"
          className="containerNotFound"
          sx={{ display: "flex" }}
        >
          <img src={unauthorized} alt="Aqui no hay nada, mi ciela" />
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Rubik",
              fontWeight: "600",
              padding: "1rem",
              colors: "#05000D",
            }}
          >
            {error?.data?.message}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontFamily: "Rubik", fontWeight: "600", margin: "1rem" }}
          >
            <Link to="/login">Volver al login</Link>
          </Button>
        </Container>
      </Container>
    );
  } else if (isSuccess && trueSuccess) {
    content = <Outlet />;
  } else if (token && isUninitialized) {
    content = <Outlet />;
  }

  return content;
};
export default PersistLogin;
