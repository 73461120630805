import { Typography, Container, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../config/routes/Routes";

const Page = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const canvas = document.getElementById("interactiveCanvas");
    const ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const circles = [];

    const getRandomColor = () => {
      const random = Math.random();
      return random < 0.9 ? "black" : "rgba(160, 25, 26, 1)"; // 60% negros, 40% rojos
    };

    for (let i = 0; i < 150; i++) {
      const radius = Math.random() * 20;
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const dx = (Math.random() - 0.5) * 2;
      const dy = (Math.random() - 0.5) * 2;
      const color = getRandomColor();

      circles.push({ x, y, radius, dx, dy, color });
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      circles.forEach((circle) => {
        ctx.beginPath();
        ctx.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2);
        ctx.fillStyle = circle.color;
        ctx.fill();
        ctx.closePath();

        circle.x += circle.dx;
        circle.y += circle.dy;

        if (
          circle.x + circle.radius > canvas.width ||
          circle.x - circle.radius < 0
        ) {
          circle.dx = -circle.dx;
        }
        if (
          circle.y + circle.radius > canvas.height ||
          circle.y - circle.radius < 0
        ) {
          circle.dy = -circle.dy;
        }
      });
      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = () => {
    navigate(LOGIN);
  };

  const fontSize      = isMobile ? "xx-large" : "3.75rem";

  const content = (
    <div className="contenedorPublic">
      <canvas id="interactiveCanvas" />
      <Container
        maxWidth="sm"
        sx={{position: 'absolute', backgroundColor: '#f0f0f0', textAlign: 'center', padding: '2rem', top: '50%', transform: 'translate(0, -80%)'}}
      >
        <Typography
          variant="h2"
          sx={{ padding: "1rem", color: "#05000D", fontFamily: "Rubik", fontSize: fontSize }}
        >
          E<span style={{color: "rgba(160, 25, 26, 1)"}}>ria</span> Consulting
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "100%", fontFamily: "Rubik" }}
          onClick={handleSubmit}
        >
          Login
        </Button>
      </Container>
    </div>
  );

  return content;
};
export default Page;
