import React, { useEffect, useState } from "react";
import { CALL_CONTAINS_MAIL, CALL_SEND_MAIL } from "../config/routes/Routes";
import {
  Container,
  TextField,
  Typography,
  Grid,
  Zoom,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { useParamContext } from "./ParamContext";
import { NEWPASS, LOGIN } from "../config/routes/Routes";
import Countdown from "./Countdown";
import axios from "axios";

function generarCodigoValidacion() {
  const caracteres = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const longitudCodigo = 6;
  let codigoGenerado = "";

  for (let i = 0; i < longitudCodigo; i++) {
    const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
    codigoGenerado += caracteres.charAt(indiceAleatorio);
  }
  return codigoGenerado;
}

function ForgottenPassword() {
  const [isDisabled, setDisabled] = useState(true);
  const [blockButton, setBlockButton] = useState(false);
  const [codeText, setCodeText] = useState("Enviar código");
  const [email, setEmail] = useState("");
  const [inputCode, setCodeInput] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [codigoValidacion, setCodigoValidacion] = useState("");

  const navigate = useNavigate();
  const { setParams } = useParamContext();

  useEffect(() => {
    if (codigoValidacion !== "") {
      sendMail();
    }
  }, [codigoValidacion]);

  const checkCredentials = async () => {
    return axios
      .get(CALL_CONTAINS_MAIL, { params: { email: email } })
      .then((response) => {
        return response.data.success;
      })
      .catch((err) => {
        return false;
      });
  };

  const sendMail = async () => {
    return axios.get(CALL_SEND_MAIL, {
      params: {
        email: email,
        code: codigoValidacion,
      },
    });
  };

  const handleVerification = () => {
    if (inputCode === codigoValidacion) {
      setInvalidCode(false);

      setParams({ parametro: email });
      navigate(NEWPASS);
    } else {
      setInvalidCode(true);
    }
  };

  const handleGoBack = () => {
    setCodigoValidacion("");
    setCodeInput("");
    setEmail("");
    navigate(LOGIN);
  };

  const handleFinish = () => {
    setCodeText("Enviar código");
    setBlockButton(false);
  };

  const handleCodeMail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(email);

    if (isValidEmail) {
      setInvalidEmail(false);
      checkCredentials().then((res) => {
        if (res) {
          setCodeText(<Countdown onFinish={handleFinish} />);
          setCodigoValidacion(generarCodigoValidacion());
          setBlockButton(true);
          setDisabled(false);
        } else {
          setInvalidEmail(true);
          setDisabled(true);
        }
      });
    } else {
      setInvalidEmail(true);
      setDisabled(true);
    }
  };

  return (
    <Container>
      <Zoom in={true} style={{ transitionDelay: "200ms" }}>
        <Container maxWidth="sm" className="formLogin" sx={{ padding: "1rem" }}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Rubik", fontWeight: "600" }}
              >
                Recuperación de contraseña
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="rounded"
                type="email"
                id="username"
                label="Correo electrónico"
                variant="outlined"
                value={email}
                error={invalidEmail}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setInvalidEmail(false);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                size="medium"
                sx={{ fontFamily: "Rubik" }}
                onClick={handleCodeMail}
                disabled={blockButton}
              >
                {codeText}
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "3rem" }}>
              <TextField
                fullWidth
                className="rounded"
                id="outlined-password-input"
                label="Codigo de verificación"
                disabled={isDisabled}
                value={inputCode}
                error={invalidCode}
                onChange={(e) => {
                  setCodeInput(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                size="medium"
                sx={{ fontFamily: "Rubik" }}
                onClick={handleVerification}
                disabled={isDisabled}
              >
                Verificar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <IconButton
                variant="contained"
                size="medium"
                sx={{ fontFamily: "Rubik" }}
                onClick={handleGoBack}
              >
                <ArrowBackIcon color="primary"/>
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Zoom>
    </Container>
  );
}

export default ForgottenPassword;
