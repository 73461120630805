import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  CALL_CREATE_UPDATE_USER,
  CALL_SERVICES,
  CALL_ROLES,
} from "../config/routes/Routes";

function AccountSettingsDashboard({ editing, data, onClose }) {
  const [formData, setFormData] = useState({
    id: "",
    docId: "",
    name: "",
    surnames: "",
    email: "",
    phone: "",
    address: "",
    service: null,
    role: null,
  });

  const [isEditing, setIsEditing] = useState(editing);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidSurnames, setInvalidSurnames] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidDNI, setInvalidDNI] = useState(false);
  const [invalidService, setInvalidService] = useState(false);
  const [invalidRole, setInvalidRole] = useState(false);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});

  useEffect(() => {
    setFormData({
      id: data.id,
      docId: data.docId,
      name: data.name,
      surnames: data.surnames,
      email: data.email,
      phone: data.phone,
      address: data.address,
      service: null,
      role: null,
    });
    getServicesOptions();
    getRolesOptions();
  }, [data]);

  function getRolesOptions() {
    axios
      .get(CALL_ROLES)
      .then((response) => {
        setRolesOptions(response.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          role: response.data.find((role) => role.id === data.roleId) || null,    
        }))
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  function getServicesOptions() {
    axios
      .get(CALL_SERVICES)
      .then((response) => {
        setServicesOptions(response.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          service: response.data.find((service) => service.id === data.serviceId) || null,
        }))
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }
  
  function validateName(name) {
    return name.trim() !== "";
  }

  function validateSurnames(surnames) {
    return surnames.trim() !== "";
  }

  function validateEmail(email) {
    return /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/.test(email);
  }

  function validatePhone(phone) {
    return /^(\+34|0034|34)?[ -]*(6)[ -]*([0-9][ -]*){8}$/.test(phone);
  }

  function validateDNI(dni) {
    let validChars = "TRWAGMYFPDXBNJZSQVHLCKET";
    let nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
    let nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
    let str = dni.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    let nie = str.replace("X", "0").replace("Y", "1").replace("Z", "2");

    let letter = str.slice(-1);
    let charIndex = parseInt(nie.slice(0, 8)) % 23;

    return validChars.charAt(charIndex) === letter;
  }

  function validateService(service) {
    return service != null && service?.id != null;
  }

  function validateRole(role) {
    return role != null && role?.id != null;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validateForm(formData)) {
      if (formData.id) {
        formData.serviceId = formData.service.id;
        formData.roleId = formData.role.id;
        axios
          .put(CALL_CREATE_UPDATE_USER, formData)
          .then((response) => {
            onClose(true);
          })
          .catch((err) => {
            if (err.response.status == "500") {
              handleSnackbarOpen(false, "Ha ocurrido un error!");
            }
            err.handleGlobally && err.handleGlobally();
          });
      }
      else
        axios
          .post(CALL_CREATE_UPDATE_USER, formData)
          .then((response) => {
            onClose(true);
          })
          .catch((err) => {
            if (err.response.status == "409") {
              handleSnackbarOpen(false, "Email ya registrado");
            }
            err.handleGlobally && err.handleGlobally();
          });
    } else {
      handleSnackbarOpen(false, "Formulario incompleto!");
    }
  }

  function handleCancel() {
    setIsEditing(false);
    onClose();
  }

  const handleInputChange = (event) => {

    const { name, value } = event.target;

    if(name == "email"){
        setInvalidEmail(!validateEmail(value));
    }
    if(name == "phone"){
      setInvalidPhone(!validatePhone(value));
    }
    if(name == "docId"){
      setInvalidDNI(!validateDNI(value));
    }
    if(name == "name"){
      setInvalidName(!validateName(value));
    }
    if(name == "surnames"){
      setInvalidSurnames(!validateSurnames(value));
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleServiceOptionChange = (e, newValue) => {
    setInvalidService(!validateService(newValue));
    setFormData((prevFormData) => ({
      ...prevFormData,
      service: newValue,
    }));
  };

  const handleRoleOptionChange = (e, newValue) => {
    setInvalidRole(!validateRole(newValue));
    setFormData((prevFormData) => ({
      ...prevFormData,
      role: newValue,
    }));
  };

  function validateForm(data) {
    let valid = true;

    if (!validateEmail(data.email)) {
      valid = false;
      setInvalidEmail(true);
    }

    if (!validatePhone(data.phone)) {
      valid = false;
      setInvalidPhone(true);
    }

    if (!validateDNI(data.docId)) {
      valid = false;
      setInvalidDNI(true);
    }

    if (!validateName(data.name)) {
      valid = false;
      setInvalidName(true);
    }

    if (!validateSurnames(data.surnames)) {
      valid = false;
      setInvalidSurnames(true);
    }

    if (!validateService(data.service)) {
      valid = false
      setInvalidService(true);
    }

    if (!validateRole(data.role)) {
      valid = false
      setInvalidRole(true)
    }

    return valid;
  }

  const handleSnackbarOpen = (success, message) => {
    let isSuccess = !(typeof(success) !== "boolean" || !success)
    let severity = isSuccess ? "success" : "error";
    setSnackbarObject({message: message, severity: severity});
    setSnackbarOpen(true);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{ fontFamily: "Rubik", fontWeight: "600", paddingBottom: "30px" }}
      >
        {formData.id=="" ? "Crear" : "Editar"} Usuario
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
        <Grid item xs={12} md={3}>
          <TextField
            label="Nombre"
            name="name"
            sx={{ width: "100%" }}
            error={invalidName}
            value={formData.name}
            InputProps={{
              readOnly: !isEditing,
            }}
            onChange={handleInputChange}
          />
          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-black text-red-800"
              hidden={invalidName ? "" : "hidden"}
            >
              Nombre es un campo obligatorio
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            label="Apellidos"
            name="surnames"
            sx={{ width: "100%" }}
            error={invalidSurnames}
            value={formData.surnames}
            InputProps={{
              readOnly: !isEditing,
            }}
            onChange={handleInputChange}
          />
          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-black text-red-800"
              hidden={invalidSurnames ? "" : "hidden"}
            >
              Apellidos es un campo obligatorio
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label="Documento de identificación"
            name="docId"
            sx={{ width: "100%" }}
            error={invalidDNI}
            value={formData.docId}
            InputProps={{
              readOnly: !isEditing,
            }}
            onChange={handleInputChange}
          />
          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-black text-red-800"
              hidden={invalidDNI ? "" : "hidden"}
            >
              Formato de DNI incorrecto
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            type="email"
            label="Email"
            name="email"
            sx={{ width: "100%" }}
            value={formData.email}
            error={invalidEmail}
            InputProps={{
              readOnly: !isEditing,
            }}
            onChange={handleInputChange}
          />

          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-black text-red-800"
              hidden={invalidEmail ? "" : "hidden"}
            >
              Formato de e-mail incorrecto
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label="Teléfono"
            name="phone"
            sx={{ width: "100%" }}
            value={formData.phone}
            error={invalidPhone}
            InputProps={{
              readOnly: !isEditing,
            }}
            onChange={handleInputChange}
          />
          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-black text-red-800"
              hidden={invalidPhone ? "" : "hidden"}
            >
              Formato de teléfono incorrecto
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            label="Dirección"
            multiline
            rows={2}
            name="address"
            sx={{ width: "100%" }}
            value={formData.address}
            InputProps={{
              readOnly: !isEditing,
            }}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Autocomplete
            id="comboboxService"
            options={servicesOptions}
            onChange={handleServiceOptionChange}
            sx={{ width: "100%" }}
            value={formData.service}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Servicio" />}
            disablePortal
            disableClearable={true}
            error={invalidService ? 1 : 0}
          />
          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-black text-red-800"
              hidden={invalidService ? "" : "hidden"}
            >
              Es necesario introducir un servicio
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Autocomplete
            id="comboboxRole"
            options={rolesOptions}
            onChange={handleRoleOptionChange}
            sx={{ width: "100%" }}
            value={formData.role}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Role" />}
            disablePortal
            disableClearable={true}
            error={invalidRole ? 1 : 0}
          />
          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-black text-red-800"
              hidden={invalidRole ? "" : "hidden"}
            >
              Es necesario introducir un rol
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Container sx={{ textAlign: "center" }}>
        
          <Button
            size="small"
            sx={{ fontFamily: "Rubik", marginRight: "1rem" }}
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>

        {formData.id=="" && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ fontFamily: "Rubik" }}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        )}

        {formData.id!="" && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ fontFamily: "Rubik" }}
            onClick={handleSubmit}
          >
            Editar
          </Button>
        )}
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        disableWindowBlurListener
      >
        <Alert
          severity={snackbarObject.severity}
          sx={{ width: "100%" }}
        >
          {snackbarObject.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AccountSettingsDashboard;
