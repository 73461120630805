import { Modal, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { CALL_ENABLE_DISABLE_USER } from "../config/routes/Routes";

function EnableDisableUserWindow({ data, onClose }) {
  const [userData, setUserData] = useState({
    id: data.id,
    name: data.name,
    surnames: data.surnames,
    email: data.email,
    docId: data.docId,
    disabled: data.disabled,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(CALL_ENABLE_DISABLE_USER, {id: userData.id})
      .then((response) => {
        onClose(true);
      })
      .catch((error) => {
        onClose(false);
        error.handleGlobally && error.handleGlobally();
      });
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Container
        className="bg-white flex-col justify-around relative rounded-lg p-4 top-1/4"
        sx={{ width: "fit-content", display: "flex" }}
      >
        <Typography variant="h5" sx={{fontFamily:"Rubik"}}>
          {(userData.disabled ? "¿Habilitar a " : "¿Deshabilitar a ") +
            userData.name +
            " " +
            userData.surnames}
          ?
        </Typography>

        <Container sx={{ display: "flex", justifyContent: "space-around", marginTop: "2rem" }}>
          <Button sx={{fontFamily:"Rubik"}} onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button sx={{fontFamily:"Rubik"}} variant="contained" color="primary" onClick={handleSubmit}>
            Aceptar
          </Button>
        </Container>
      </Container>
    </Modal>
  );
}

export default EnableDisableUserWindow;
