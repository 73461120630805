import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const ComboNestedCheckbox = ({ comboId, fatherOptions, childOptions, style, label, inputValues, onChange }) => {
  const [cambio, setCambio] = useState(false);
  const [value, setValue] = useState([]);
  const [privileges, setPrivileges] = useState(fatherOptions);
  const [childPrivileges, setChildPrivileges] = useState(childOptions);

  useEffect(() => {
    setPrivileges(fatherOptions);
    setChildPrivileges(childOptions);
    handleTextValue(inputValues);
  }, [fatherOptions, childOptions, privileges, childPrivileges]);

  useEffect(() => {}, [cambio]);

  const handleTextValue = (options) => {
    let comboValueNames = [];
    options.forEach((option) => {
      if (!option.father) {
        if (privileges.length > 0) {
          let privilege = privileges.find((x) => x.id === option.id);
          privilege.checked = true;
          privilege.expanded = option.expanded === undefined ? true : option.expanded;
          comboValueNames.push(privilege.name);
        }
      } else {
        if (childPrivileges.length > 0) {
          let childPrivilege = childPrivileges.find((x) => x.id === option.id);
          childPrivilege.checked = true;
          comboValueNames.push(childPrivilege.name);
        }
      }
    });
    privileges.forEach((privilege) => privilege.allChildsSelected = !childPrivileges.some(
      (childPrivilege) => childPrivilege.father === privilege.id && !childPrivilege.checked));
      
    if (comboValueNames.length === 0) comboValueNames.push("Sin privilegios")
    setValue(comboValueNames);
    setCambio(!cambio);
  };

  const handleCheckedOptions = () => {
    let optionsChecked = [];
    privileges.forEach((privilege) => {
      if (privilege.checked)
        optionsChecked.push({
          id: privilege.id,
          name: privilege.name,
          father: null,
          expanded: privilege.expanded
        });
    });
    childPrivileges.forEach((childPrivilege) => {
      if (childPrivilege.checked)
        optionsChecked.push({
          id: childPrivilege.id,
          name: childPrivilege.name,
          father: childPrivilege.father,
        });
    });
    handleTextValue(optionsChecked);
    onChange(optionsChecked);
  };

  const handleExpandClick = (option) => {
    option.expanded = !option.expanded;
    setCambio(!cambio);
  };

  const handleCheckClick = (option, fatherIndex) => {
    option.checked = !option.checked;
    if (!option.father) {
      childPrivileges.forEach((childPrivilege) => {
        if (childPrivilege.father === option.id)
          childPrivilege.checked = option.checked;
      });
      if (option.checked) option.expanded = option.checked;
    } else {
      let keepFatherChecked = false;
      let allChildsSelected = true;
      childPrivileges.forEach((childPrivilege) => {
        if (childPrivilege.father === privileges[fatherIndex].id) {
          if (childPrivilege.checked) keepFatherChecked = true;
          if (!childPrivilege.checked) allChildsSelected = false;
        }
      });
      privileges[fatherIndex].checked = keepFatherChecked;
      privileges[fatherIndex].allChildsSelected = allChildsSelected
    }
    handleCheckedOptions();
    setCambio(!cambio);
  };
 
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        id={comboId}
        style={style}
        label={label}
        value={value}
        multiple
        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
        renderValue={(names) => {
          let namesValue = "";
          names.forEach((name, index) => {
            if (index === 0) namesValue += name;
            else namesValue += ", " + name;
          });
          return namesValue;
        }}
      >
        <MenuList>
          {privileges.length > 0 && privileges.map((privilege, fatherIndex) => (
              <Container key={"container" + fatherIndex}>
                <MenuItem
                  key={privilege.id}
                  name={privilege.name}
                  onClick={() => handleExpandClick(privilege)}
                  dense
                >
                  <Checkbox
                    checked={privilege.checked}
                    onChange={() => handleCheckClick(privilege)}
                    checkedIcon={privilege.allChildsSelected ? <CheckBoxIcon /> : <CheckBoxIcon sx={{ color: "gray" }} />}
                  />
                  <ListItemText primary={privilege.name} />
                  <IconButton>
                    {privilege.expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </MenuItem>
                <Collapse
                  unmountOnExit
                  in={privilege.expanded || false}
                  timeout="auto"
                >
                  {childPrivileges.length > 0 && childPrivileges.map(
                      (childPrivilege) =>
                        childPrivilege.father === privilege.id && (
                          <MenuItem
                            key={childPrivilege.id}
                            name={childPrivilege.name}
                            style={{ paddingLeft: "40px" }}
                            dense
                          >
                            <Checkbox
                              checked={childPrivilege.checked}
                              onChange={() =>
                                handleCheckClick(childPrivilege, fatherIndex)
                              }
                            />
                            <ListItemText primary={childPrivilege.name} />
                          </MenuItem>
                        )
                    )}
                </Collapse>
              </Container>
            ))}
        </MenuList>
      </Select>
    </FormControl>
  );
};

export default ComboNestedCheckbox;
