// PADRES
export const DASH = 1;
export const G_PER = 3;
export const G_INC = 5;
export const G_USU = 11;
export const G_DEP = 15;
export const G_ROL = 18;
export const G_REP = 22;
export const G_COD = [27];

// HIJOS
export const DASH_FICH = 2;
export const G_PER_SEE = 4;
export const G_INC_FICH = 6;
export const G_INC_INC_OWN = 7;
export const G_INC_ADD_INC = 8;
export const G_INC_INC_ALL = 9;
export const G_INC_RSLV = 10;
export const G_USU_ADD = 12;
export const G_USU_UPD = 13;
export const G_USU_HAB = 14;
export const G_DEP_ADD = 16;
export const G_DEP_DEL = 17;
export const G_ROL_ADD = 19;
export const G_ROL_UPD = 20;
export const G_ROL_DEL = 21;
export const G_REP_CREATE = 23;
export const G_REP_FICH = 24;
export const G_INC_PDF_FICH = 25;
export const G_INC_PDF_INC = 26;


// BLOQUES DE HIJOS
export const BLQ_DASH = [DASH, DASH_FICH];
export const BLQ_G_PER = [G_PER, G_PER_SEE];
export const BLQ_G_INC = [G_INC, G_INC_ADD_INC, G_INC_FICH, G_INC_INC_ALL, G_INC_INC_OWN, G_INC_RSLV, G_INC_PDF_FICH, G_INC_PDF_INC];
export const BLQ_G_USU = [G_USU, G_USU_ADD, G_USU_HAB, G_USU_UPD];
export const BLQ_G_DEP = [G_DEP, G_DEP_ADD, G_DEP_DEL];
export const BLQ_G_ROL = [G_ROL, G_ROL_ADD, G_ROL_DEL, G_ROL_UPD];
export const BLQ_G_REP = [G_REP, G_REP_CREATE, G_REP_FICH];