import { Typography, Container } from "@mui/material"
import { Link } from 'react-router-dom'
import notFound from "../../resources/inspector-gadget.png"
import eriaLogo from "../../resources/eria.png"

function NotFoundComponent() {
    return (
        <Container>
            <Container maxWidth="sm" className="containerNotFound" sx={{display:"flex"}}>
                <img src={notFound} alt="Aqui no hay nada, mi ciela"/>
                <Typography variant="h4" sx={{fontFamily: "Rubik", padding: "1rem", colors: "#05000D"}}>404. Error</Typography>
                <Container maxWidth="sm" sx={{display:"flex"}}>
                    <img src={eriaLogo} alt="Aqui no hay nada, mi ciela" style={{paddingTop: "1rem", width: "80px", height: "80px", alignSelf: 'center'}}/>
                    <Typography variant="body1" sx={{fontStyle: 'italic', paddingTop: "1rem", textAlign: "center"}}>
                        "Todo pasa y todo queda, 
                        pero lo nuestro es pasar,
                        pasar haciendo caminos, 
                        caminos sobre el mar"
                    </Typography>
                </Container>
                <Link className='colorLink' to="/login">Volver al inicio</Link>
            </Container>
        </Container>
    );
}

export default NotFoundComponent;
