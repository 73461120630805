import { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Typography,
  Grid,
  Zoom,
  Button,
  Snackbar,
  Alert,
  IconButton
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { useParamContext } from "./ParamContext";
import { LOGIN } from "../config/routes/Routes";
import axios from "axios";
import { CALL_UPDATE_PASSWORD } from "../config/routes/Routes";

function NewPassword({ route, navigation }) {
  const [repiteContraseña, setRepiteContraseña] = useState("");
  const [nuevaContraseña, setNuevaContraseña] = useState("");
  const [errorContraseña, setErrorContraseña] = useState("");
  const [errorRepiteContraseña, setErrorRepiteContraseña] = useState("");
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});
  const { params } = useParamContext();

  const navigate = useNavigate();

  useEffect(() => {
    const contraseñasValidas =
      validarContraseña(nuevaContraseña) &&
      validarRepiteContraseña(repiteContraseña, nuevaContraseña);
    setBotonDeshabilitado(!contraseñasValidas);
  }, [nuevaContraseña, repiteContraseña]);

  const handlerVerificarContraseñas = () => {
    if (!params.parametro) {
      handleClick();
    } else {
      axios
        .put(CALL_UPDATE_PASSWORD, {
          params: { email: params.parametro, password: nuevaContraseña },
        })
        .then((response) => {
          handleSnackbarOpen(true, response.data.message);
        })
        .catch((err) => {
          handleSnackbarOpen(false, "Error actualizando contraseña");
        });
    }
  };

  const validarContraseña = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]+.{7,}$/;

    if (regex.test(password)) {
      setErrorContraseña("");
      return true;
    } else {
      setErrorContraseña("La contraseña debe ser de mínimo 8 carácteres, contener al menos una mayúscula, un dígito y un carácter especial.");
      return false;
    }
  };

  const validarRepiteContraseña = (password, nuevaPassword) => {
    if (password === nuevaPassword) {
      setErrorRepiteContraseña("");
      return true;
    } else {
      setErrorRepiteContraseña("Las contraseñas no coinciden.");
      return false;
    }
  };

  const handleGoBack = () => {
    setRepiteContraseña("");
    setNuevaContraseña("");
    params.parametro = "";
    navigate(LOGIN);
  };

  const handleChangeContraseña = (e) => {
    setNuevaContraseña(e.target.value);
    validarContraseña(e.target.value);
  };

  const handleChangeRepiteContraseña = (e) => {
    setRepiteContraseña(e.target.value);
    validarRepiteContraseña(e.target.value, nuevaContraseña);
  };

  const handleClick = () => {
    handleSnackbarOpen(false, "No tienes permisos para realizar esta acción!")
  };

  const handleSnackbarOpen = (success, message) => {
    let isSuccess = !(typeof(success) !== "boolean" || !success)
    let severity = isSuccess ? "success" : "error";
    setSnackbarObject({message: message, severity: severity});
    setSnackbarOpen(true);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
    navigate(LOGIN);
  };

  return (
    <Container>
      <Zoom in={true} style={{ transitionDelay: "200ms" }}>
        <Container maxWidth="sm" className="formLogin" sx={{ padding: "1rem" }}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Rubik", fontWeight: "600" }}
              >
                Recuperación de contraseña
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                className="rounded"
                id="outlined-password-input"
                label="Nueva contraseña"
                type="password"
                autoComplete="current-password"
                value={nuevaContraseña}
                onChange={handleChangeContraseña}
                error={Boolean(errorContraseña)}
                helperText={errorContraseña}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                className="rounded"
                id="outlined-password-input"
                label="Repetir contraseña"
                type="password"
                autoComplete="current-password"
                value={repiteContraseña}
                onChange={handleChangeRepiteContraseña}
                error={Boolean(errorRepiteContraseña)}
                helperText={errorRepiteContraseña}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                size="medium"
                sx={{ fontFamily: "Rubik" }}
                onClick={handlerVerificarContraseñas}
                disabled={botonDeshabilitado}
              >
                Guardar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <IconButton
                variant="contained"
                size="medium"
                sx={{ fontFamily: "Rubik" }}
                onClick={handleGoBack}
              >
                <ArrowBackIcon color="primary"/>
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Zoom>
      <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          disableWindowBlurListener
        >
          <Alert
            severity={snackbarObject.severity}
            sx={{ width: "100%" }}
          >
            {snackbarObject.message}
          </Alert>
        </Snackbar>    
    </Container>
  );
}

export default NewPassword;
