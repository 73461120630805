import { Container } from "@mui/system";
import { Typography, Grid, Button, CircularProgress } from "@mui/material";
import Clock from "./Clock";
import CurrentDateContainer from "./CurrentDateContainer";
//import ClockInButton from "./ClockInButton";
import ClockInElement from "./ClockInElement";
import { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import moment from "moment";
import { CALL_LASTACTION, CALL_SIGNING, CALL_TODAYSIGNINGS } from "../config/routes/Routes";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";

function DummyDashboard() {
  const [elemlist, setElemlist] = useState([]);
  const [isClockIn, setIsClockIn] = useState();
  const [loading, setLoading] = useState(false);

  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const canSeeSigning = userPrivileges.includes(Privileges.DASH_FICH);

  const getElemList = () =>
    elemlist.map((e, i) => drawClockInElement(e.actionId, e.date, "item" + i));

  useEffect(() => {
    moment().locale("es-ES");
    getLastCheckType();
    loadCheckIn();
    setLoading(false);
  }, [isClockIn]);

  function loadCheckIn() {
    //Llamada para cargar los fichajes del dia
    axios.get(CALL_TODAYSIGNINGS).then((response) => {
      setElemlist(response.data);
    }).catch(error => {
        error.handleGlobally && error.handleGlobally();
    });
  }

  function getLastCheckType() {
    axios.get(CALL_LASTACTION).then((response) => {
      response.data.data === 1 ? onSubmitCheck(false) : onSubmitCheck(true);
    }).catch(error => {
      error.handleGlobally && error.handleGlobally();
    });
  }

  function onClickButton() {
    setLoading(true);
    axios.post(CALL_SIGNING)
    .then((response) => {
      onSubmitCheck(!isClockIn);
    })
    .catch((err) => {
      err.handleGlobally && err.handleGlobally();
    });
  }

  function onSubmitCheck(isClockIn) {
    setIsClockIn(isClockIn);
  }

  function drawClockInElement(checkInType, checkInDate, i) {
    return (
      <Grid item key={i} sx={{ minWidth: "-webkit-fill-available" }}>
        <ClockInElement
          props={{
            checkInType: checkInType,
            checkInDate: checkInDate,
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={5}
      sx={{minHeight:'50vh'}}
    >
      <Grid item>
        <Container
          fixed
          className="bg-transparent rounded-lg text-center inline-block p-10 top-1/4"
          sx={{borderColor: "black"}}
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Grid item xs={12} md={6}>
              <Clock />
            </Grid>
            <Grid item xs={12} md={!canSeeSigning ? 5 : 6} sx={{ marginLeft: !canSeeSigning ? '50px' : '0px'}}>
              <CurrentDateContainer />
            </Grid>

            {canSeeSigning && (<Grid item xs={12} md={12}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                variant="contained"
                  sx={{
                    display: "block",
                    height: "150%",
                    minWidth: "100%"
                  }}
                  onClick={onClickButton}
                >
                  <Typography variant="h6" sx={{fontFamily: "Rubik", fontWeight: "600" }}>
                    Fichar {isClockIn ? "Entrada" : "Salida"}
                  </Typography>
                </Button>
              )}
            </Grid>)}
          </Grid>
        </Container>
      </Grid>

      <Grid item>
        <Container
          sx={{
            maxHeight: "220px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: 5,
              backgroundColor: "#e5e7eb60",
              //outline: "1px solid slategrey",
            },
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {getElemList()}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default DummyDashboard;
