// PATHS

export const HOME                   = '/';
export const LOGIN                  = '/login';
export const DASHBOARD              = '/dashboard'
export const INCIDENT               = '/incidencias'
export const NEWPASS                = '/newPassword'

// ROUTES

export const server = 'https://ch.eriaconsulting.com:8080'

export const CALL_LOGIN                 = server + '/login'
export const CALL_LASTACTION            = server + '/getlastaction'
export const CALL_SIGNING               = server + '/signing'
export const CALL_TODAYSIGNINGS         = server + '/getTodaySignings'
export const CALL_VERIFY                = server + '/verify'
export const CALL_INCIDENCE             = server + '/incidence'
export const CALL_SERVICES              = server + '/getService'
export const CALL_EDITSERVICE           = server + '/addEditService'
export const CALL_DELETESERVICE         = server + '/deleteService'

export const CALL_USERS                 = server + '/getUsers'
export const CALL_USER_DATA             = server + '/getUser'
export const CALL_CREATE_UPDATE_USER    = server + '/user'
export const CALL_ENABLE_DISABLE_USER   = server + '/enableOrDisableUser'
export const CALL_CONTAINS_MAIL         = server + '/containsMail'
export const CALL_SEND_MAIL             = server + '/sendMail'
export const CALL_VALIDATE_PASSWORD     = server + '/validatePassword'
export const CALL_UPDATE_PASSWORD       = server + '/updatePassword'

export const CALL_UPDATE_USER           = server + '/user'
export const CALL_REPORT                = server + '/getReports'
export const CALL_REPORT_SIGNINGS       = server + '/getFilteredSignings'

export const CALL_ROLES                 = server + '/getRoles'
export const CALL_ROLE_PRIVILEGES       = server + '/getRolePrivileges'
export const CALL_ROLE_GENERIC          = server + '/role'

export const CALL_PRIVILEGES            = server + '/getPrivileges'

export const CALL_USER_SIGNINGS         = server + '/getSigningsByUser'
export const CALL_SIGNING_RESUMES       = server + '/getSigningsResume'
export const CALL_INCIDENCES            = server + '/getIncidences'
export const CALL_USER_INCIDENCES       = server + '/getUserIncidences'

export const CALL_CARDS                 = server + '/getCards'
export const CALL_CARD_GENERIC          = server + '/card'
