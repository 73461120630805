import {
  Container,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import MySigningsDashboard from "./MySigningsDashboard";
import MyIncidencesDashboard from "./MyIncidencesDashboard";
import IncidenceRequestsMGDashboard from "./IncidenceRequestsMGDashboard";
import { styled } from "@mui/material/styles";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontFamily: "Rubik",
    fontWeight: "600",
  })
);

function SigninigsAndIncidencesDashboard() {
  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const canSeeOwnSignings = userPrivileges.includes(Privileges.G_INC_FICH);
  const canSeeOwnIncidences = userPrivileges.includes(Privileges.G_INC_INC_OWN);
  const canSeeAllIncidences = userPrivileges.includes(Privileges.G_INC_INC_ALL);

  const defaultValue = canSeeOwnSignings ? 0 : canSeeOwnIncidences ? 1 : canSeeAllIncidences ? 2 : 0
  const [value, setValue] = React.useState(defaultValue);
  const [refresh, setRefresh] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ height: "80vh" }}>
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {canSeeOwnSignings && (<StyledTab label="Mis fichajes" {...a11yProps(0)} value={0} />)}
          {canSeeOwnIncidences && (<StyledTab label="Mis Incidencias" {...a11yProps(1)} value={1} />)}
          {canSeeAllIncidences && (<StyledTab label="Gestión de Incidencias" {...a11yProps(2)} value={2} />)}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MySigningsDashboard display={value === 0} refresh={refresh} setRefresh={setRefresh} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MyIncidencesDashboard display={value === 1} refresh={refresh} setRefresh={setRefresh} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <IncidenceRequestsMGDashboard display={value === 2} refresh={refresh} setRefresh={setRefresh} />
      </TabPanel>
    </Container>
  );
}

export default SigninigsAndIncidencesDashboard;
