import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Grid,
  Button
} from "@mui/material";
import React from "react";
import axios from "axios";
import { CALL_USER_DATA, CALL_VALIDATE_PASSWORD } from "../config/routes/Routes";

function MyProfile() {
  const [profileData, setProfileData] = useState({
    id: "",
    docId: "",
    name: "",
    surnames: "",
    email: "",
    phone: "",
    address: "",
  });

  const [antiguaContraseña, setAntiguaContraseña] = useState("");
  const [repiteContraseña, setRepiteContraseña] = useState("");
  const [nuevaContraseña, setNuevaContraseña] = useState("");
  const [errorContraseña, setErrorContraseña] = useState("");
  const [errorRepiteContraseña, setErrorRepiteContraseña] = useState("");
  const [errorOldContraseña, setErrorOldContraseña] = useState("");
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});


  useEffect(() => {
    const contraseñasValidas = validarContraseña(nuevaContraseña) && validarRepiteContraseña(repiteContraseña, nuevaContraseña);
    setBotonDeshabilitado(!contraseñasValidas);
  }, [nuevaContraseña, repiteContraseña]);

  useEffect(() => {
    getProfileData();
  }, []);

  function getProfileData() {
    axios
      .get(CALL_USER_DATA)
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const handleChangeOldContraseña = (e) => {
    setAntiguaContraseña(e.target.value);
  };

  const handleChangeContraseña = (e) => {
    setNuevaContraseña(e.target.value);
    validarContraseña(e.target.value);
  };

  const handleChangeRepiteContraseña = (e) => {
    setRepiteContraseña(e.target.value);
    validarRepiteContraseña(e.target.value, nuevaContraseña);
  };

  const validarContraseña = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]+.{7,}$/;
    if (regex.test(password)) {
      setErrorContraseña("");
      return true;
    } else {
      setErrorContraseña("La contraseña debe ser de mínimo 8 carácteres, contener al menos una mayúscula, un dígito y un carácter especial.");
      return false;
    }
  };

  const validarRepiteContraseña = (password, nuevaPassword) => {
    if (password === nuevaPassword) {
      setErrorRepiteContraseña("");
      return true;
    } else {
      setErrorRepiteContraseña("Las contraseñas no coinciden.");
      return false;
    }
  };

  const handlerCambioContraseña = async () => {
    axios.get(CALL_VALIDATE_PASSWORD, {
      params: { user: profileData.email, password: antiguaContraseña, newPassword: nuevaContraseña }
    }).then((response) => {
      handleSnackbarOpen(true, response.data.message);

      setAntiguaContraseña("");
      setNuevaContraseña("");
      setRepiteContraseña("");
      setErrorOldContraseña("");
    }).catch((err) => {
      setErrorOldContraseña("Incorrecta");
      handleSnackbarOpen(false, "Error actualizando contraseña");
    });
  };

  const handleSnackbarOpen = (success, message) => {
    let isSuccess = !(typeof(success) !== "boolean" || !success)
    let severity = isSuccess ? "success" : "error";
    setSnackbarObject({message: message, severity: severity});
    setSnackbarOpen(true);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <Container sx={{ height: "70vh" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
          fontFamily: "Rubik",
          marginBottom: "1rem",
        }}
      >
        Mi Perfil
      </Typography>
      <Container
        className="p-4 flex-col center"
        sx={{ width: "fit-content", display: "flex" }}
      >
        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
          <Grid item xs={12} md={3}>
            <TextField
              label="Nombre"
              name="name"
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              value={profileData.name}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              label="Apellidos"
              name="surnames"
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              value={profileData.surnames}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Documento de identificación"
              name="docId"
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              value={profileData.docId}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              type="email"
              label="Email"
              name="email"
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              value={profileData.email}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Teléfono"
              name="phone"
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              value={profileData.phone}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Dirección"
              rows={2}
              name="address"
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              value={profileData.address}
              disabled
            />
          </Grid>
          <Typography
              variant="h4"
              sx={ { fontWeight: "600", fontFamily: "Rubik", marginBottom: "1rem", marginTop: "4rem" } }
          >Cambio de Contraseña
          </Typography>
          <Grid item xs={12}>
              <TextField
                fullWidth
                className="rounded"
                id="outlined-password-input"
                label="Antigua contraseña"
                type="password"
                autoComplete="current-password"
                value={antiguaContraseña}
                onChange={handleChangeOldContraseña}
                error={Boolean(errorOldContraseña)}
                helperText={errorOldContraseña}
              />
            </Grid>
          <Grid item xs={12}>
              <TextField
                fullWidth
                className="rounded"
                id="outlined-password-input"
                label="Nueva contraseña"
                type="password"
                autoComplete="current-password"
                value={nuevaContraseña}
                onChange={handleChangeContraseña}
                error={Boolean(errorContraseña)}
                helperText={errorContraseña}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="rounded"
                id="outlined-password-input"
                label="Repetir contraseña"
                type="password"
                autoComplete="current-password"
                value={repiteContraseña}
                onChange={handleChangeRepiteContraseña}
                error={Boolean(errorRepiteContraseña)}
                helperText={errorRepiteContraseña}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                size="medium"
                sx={{ fontFamily: "Rubik" }}
                onClick={handlerCambioContraseña}
                disabled={botonDeshabilitado}
              >
                Guardar
              </Button>
            </Grid>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          disableWindowBlurListener
        >
          <Alert
            severity={snackbarObject.severity}
            sx={{ width: "100%" }}
          >
            {snackbarObject.message}
          </Alert>
        </Snackbar>     
      </Container>
    </Container>
  );
}

export default MyProfile;
