import { Modal, Container, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";

function IncidenceMoreDataWindow({ data, onClose }) {
  const styles = {
    button: {
      position: "absolute",
      top: "10px",
      right: "15px",
      backgroundColor: "transparent",
      border: "none",
      color: "white",
      cursor: "pointer",
      fontSize: "20px",
      fontWeight: "bold",
      padding: "0",
    },
  };

  function renderSwitch(validationId) {
    switch (validationId) {
      case 1:
        return "Pendiente";
      case 2:
        return "Aceptada";
      case 3:
        return "Rechazada";
      default:
        return "Sin Estado";
    }
  }

  return (
    <Modal open={true} onClose={onClose}>
      <Container className=" bg-white flex-col justify-around relative rounded-lg p-4 top-1/4 h-auto"
        sx={{ width: "fit-content", display: "flex" }} >
        <Typography sx={{ margin: "1rem", fontFamily: "Rubik", fontWeight: "600" }} variant="h5">
          Datos de la incidencia
        </Typography>

        <Container>
          <Container className="mb-4">
            <TextField
              fullWidth
              label="Incidencia ID"
              multiline
              inputProps={{ readOnly: true, maxLength: 140 }}
              value={data.id}
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              disabled
            />
          </Container>

          <Container className="mb-4">
            <TextField
              fullWidth
              label="Fichaje ID"
              multiline
              inputProps={{ readOnly: true, maxLength: 140 }}
              value={data.signingId}
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              disabled
            />
          </Container>

          <Container className="mb-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                fullWidth
                label="Fecha y Hora"
                format="DD/MM/YYYY HH:mm A"
                inputProps={{ readOnly: true, maxLength: 140 }}
                value={dayjs(data.date)}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                disabled
              />
            </LocalizationProvider>
          </Container>

          <Container className="mb-4">
            <TextField
              fullWidth
              label="Acción"
              multiline
              inputProps={{ readOnly: true, maxLength: 140 }}
              value={data.Signing.actionId == 1 ? "Entrada" : "Salida"}
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              disabled
            />
          </Container>

          <Container className="mb-4">
            <TextField
              fullWidth
              label="Validación"
              multiline
              inputProps={{ readOnly: true, maxLength: 140 }}
              value={renderSwitch(data.validationId)}
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              disabled
            />
          </Container>

          <Container className="mb-4">
            <TextField
              fullWidth
              label="Descripción"
              multiline
              inputProps={{ readOnly: true, maxLength: 140 }}
              value={data.description || ""}
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              disabled
            />
          </Container>

          <Container className="mb-4">
            <TextField
              fullWidth
              label="Motivo"
              multiline
              inputProps={{ readOnly: true, maxLength: 140 }}
              value={data.reply || ""}
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              disabled
            />
          </Container>

        </Container>
      </Container>
    </Modal>
  );
}
export default IncidenceMoreDataWindow;
