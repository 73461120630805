import { useState, useEffect } from "react";
import { Typography, Divider, Container } from "@mui/material";

function Footer() {
  return (
    <Container className="bottom-2 absolute">
      {/* <Divider
        sx={{
          "&::before, &::after": {
            borderColor: "white",
          },
        }}
      />
      <Typography sx={{ fontStyle: "italic" }}>
        Eria Consulting © 2023
      </Typography> */}
    </Container>
  );
}
export default Footer;
