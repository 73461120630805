import { useState, useEffect } from "react";
import { Typography } from "@mui/material";

function Clock() {
  const [date, setDate] = useState(new Date());

  function refreshClock() {
    setDate(new Date());
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  return (
    <Typography variant="h2" color="black" sx={{fontSize:86}}>
      {date.toLocaleTimeString()}
    </Typography>
  );
}
export default Clock;
