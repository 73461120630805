import { Typography, Container } from "@mui/material";
import moment from "moment";
import 'moment/locale/es'; 
import { useEffect, useState } from "react";

function ClockInElement({ props }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    moment().locale("es-ES");
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const widthMobile = isMobile ? "300px" : "477px";

  return (
    <Container className="rounded-lg p-4" sx={{width: widthMobile, border:"2px solid", borderColor: props.checkInType == 1 ? "black" : "rgba(160, 25, 26, 1)"}}>
      <Typography variant="h5" color={"black"} textAlign={"center"}>
        {capitalize(moment(props.checkInDate).format('D MMMM YYYY, HH:mm:ss '))} ({props.checkInType == 1 ? "Entrada" : "Salida"})
      </Typography>
    </Container>
  );
}

export default ClockInElement;
