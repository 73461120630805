import { Modal, Button, Container, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import axios from "axios";
import dayjs from "dayjs";
import { CALL_INCIDENCE } from "../config/routes/Routes";

function ManageIncidenceWindow({ data, onClose }) {

  const [formData, setFormData] = useState({
    date: data.date,
    signingId: data.signingId,
    validationId: data.validationId,
    reply: data.reply,
    description: data.description
  });

  useEffect(() => {
    if (formData.validationId !== 1) {
      postRequest();
    }
  }, [formData]);

  const postRequest = () => {
    axios
      .post(CALL_INCIDENCE, formData)
      .then((response) => {
        onClose(true);
      })
      .catch((error) => {
        onClose(false);
        error.handleGlobally && error.handleGlobally();
      });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit =  (e, validationId) => {
    e.preventDefault();

    setFormData((prevFormData) => {
      return { ...prevFormData, validationId };
    });

  };

  const styles = {
    button: {
      position: "absolute",
      top: "10px",
      right: "15px",
      backgroundColor: "transparent",
      border: "none",
      color: "white",
      cursor: "pointer",
      fontSize: "20px",
      fontWeight: "bold",
      padding: "0",
    },
  };

  function closeButton() {
    return (
      <button onClick={onClose} style={styles.button}>
        x
      </button>
    );
  }

  return (
    <Modal open={true} onClose={onClose}>
      <Container
        className=" bg-white flex-col justify-around relative rounded-lg p-4 top-1/4 h-auto"
        sx={{ width: "fit-content", display: "flex" }}
      >
        {closeButton()}
        <Typography sx={{margin: "2rem", fontFamily: "Rubik", fontWeight: "600"}} variant="h5">Gestión de Incidencia</Typography>

        <Container>
          <Container className="mb-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                label="Fecha y Hora"
                name="date"
                value={dayjs(data.date)}
                readOnly
              />
            </LocalizationProvider>
          </Container>

          <Container className="mb-4">
            <TextField
              fullWidth
              label="Acción"
              multiline
              inputProps={{ readOnly: true, maxLength: 140 }}
              name="incidenceAction"
              value={data.Signing.actionId==1?"Entrada":"Salida"}
            />
          </Container>

          <Container className="mb-4">
            <TextField
              fullWidth
              label="Descripción"
              multiline
              inputProps={{ readOnly: true, maxLength: 140 }}
              name="description"
              value={formData.description}
            />
          </Container>

          <Container className="mb-4">
            <TextField
              fullWidth
              label="Motivo"
              multiline
              inputProps={{ maxLength: 140 }}
              onChange={handleInputChange}
              required
              name="reply"
            />
          </Container>
        </Container>

        <Container sx={{ display: "flex", justifyContent: "space-around", margin: "1rem 0" }}>
          <Button variant="contained" color="primary" sx={{fontFamily: "Rubik"}} onClick={(e) => handleSubmit(e, 2)}>
            Aprobar
          </Button>
          <Button variant="contained" color="error" sx={{fontFamily: "Rubik"}} onClick={(e) => handleSubmit(e, 3)}>
            Denegar
          </Button>
        </Container>
      </Container>
    </Modal>
  );
}

export default ManageIncidenceWindow;
