import React from "react";
import { Route, HashRouter, Routes } from "react-router-dom";
import Login from "../../Login";
import Public from "../../components/Public";
import Layout from "../../components/Layout";
import PersistLogin from "../../features/auth/PersistLogin";
import RequireAuth from "../../features/auth/RequireAuth";
import Base from "../../Base";
import * as Privileges from "../roles/Privileges";
import ForgottenPassword from "../../components/ForgottenPassword";
import NewPassword from "../../components/NewPassword";
 
function RoutesTree() {
   return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route index element={<Public />} />
          <Route path="login" element={<Login />} />
          <Route path="forgottenPassword" element={<ForgottenPassword />} />
          <Route path="newPassword" element={<NewPassword />} />
          <Route element={<PersistLogin />}>
            {/* protected routes */}
            <Route path="dashboard">
              <Route index element={<Base seccion={"dashboard"} />} />
            </Route>
            <Route element={<RequireAuth allowedPrivileges={Privileges.BLQ_G_PER} />}>
              <Route path="profile">
                <Route index element={<Base seccion={"profile"} />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedPrivileges={Privileges.BLQ_G_INC} />}>
              <Route path="incidents">
                <Route index element={<Base seccion={"incidents"} />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedPrivileges={Privileges.BLQ_G_DEP} />}>
              <Route path="services">
                <Route index element={<Base seccion={"services"} />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedPrivileges={Privileges.BLQ_G_REP} />}>
              <Route path="reports">
                <Route index element={<Base seccion={"reports"} />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedPrivileges={Privileges.BLQ_G_USU} />}>
              <Route path="users">
                <Route index element={<Base seccion={"users"} />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedPrivileges={Privileges.BLQ_G_ROL} />}>
              <Route path="roles">
                <Route index element={<Base seccion={"roles"} />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedPrivileges={Privileges.G_COD} />}>
              <Route path="cards">
                <Route index element={<Base seccion={"cards"} />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default RoutesTree;
