import React, { createContext, useContext, useState } from 'react';

const ParamContext = createContext();

export const useParamContext = () => {
  return useContext(ParamContext);
};

export const ParamProvider = ({ children }) => {
  const [params, setParams] = useState({}); // Inicializa los parámetros en un estado

  return (
    <ParamContext.Provider value={{ params, setParams }}>
      {children}
    </ParamContext.Provider>
  );
};