import { Typography, Container } from "@mui/material"
import { Link } from 'react-router-dom'
import gandalfImage from "../../resources/gandalf.png"
import eriaLogo from "../../resources/eria.png"

function ForbiddenPageComponent() {
    return (
        <Container>
            <Container maxWidth="sm" className="containerNotFound" sx={{display:"flex"}}>
                <img src={gandalfImage} alt="Aqui no puedes, mi ciela"/>
                <Typography variant="h4" sx={{fontFamily: "Rubik", colors: "#05000D"}}>You Shall not Pass</Typography>
                <Container maxWidth="sm" sx={{display:"flex"}}>
                    <img src={eriaLogo} alt="Aqui no hay nada, mi ciela" style={{paddingTop: "1rem", width: "80px", height: "80px", alignSelf: 'center'}}/>
                    <Typography variant="body1" sx={{fontStyle: 'italic', paddingTop: "1rem", colors: "#05000D", textAlign: "center"}}>
                      "Soy siervo del Fuego Secreto, <br/>administrador de la llama de Anor.  <br/>Tu fuego oscuro es en vano, llama de Udûn. <br/>¡Regresa a la Sombra!"
                    </Typography>
                </Container>
                <Link className='colorLink' to="/dashboard">Volver al Inicio</Link>
            </Container>
        </Container>
    );
}

export default ForbiddenPageComponent;