import {
  Container,
  Typography,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Button,
  Grid,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import moment from "moment";
import { esES } from "@mui/x-data-grid";
import CreateIncidentWindow from "./CreateIncidentWindow";
import dayjs from "dayjs";
import StyledDataGrid from "./StyledDataGrid";
import CustomPagination from "../CustomPagination";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";
import {
  CALL_REPORT,
  CALL_SERVICES,
  CALL_USER_DATA,
  CALL_USER_SIGNINGS,
} from "../config/routes/Routes";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function MySigningsDashboard({ display, refresh, setRefresh }) {
  const [elemlist, setElemlist] = useState([]);
  const [rows, setRows] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});

  //Variables para el modal
  const [selectedData, setSelectedData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const canCreateIncidence = userPrivileges.includes(Privileges.G_INC_ADD_INC);
  const canPdfFichajes = userPrivileges.includes(Privileges.G_INC_PDF_FICH);


  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
  });

  const handleInitialDate = (nwDate) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: nwDate,
    }));
  };

  const handleFinalDate = (nwDate) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: nwDate,
    }));
  };

  function onEditIncidencesRow(params) {
    //Abrir Ventana Modificar
    setSelectedData(params.row);
    setModalOpen(true);
  }

  const getElemList = () =>
    elemlist.map((e, i) =>
      setRows(rows.push({ id: i, col1: e.actionId, col2: e.date }))
    );

  useEffect(() => {
    getSignings();
    moment().locale("es-ES");
  }, [refresh]);

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "actionId", headerName: "Tipo", flex: 1 },
    {
      field: "date",
      headerName: "Fecha",
      flex: 3,
      renderCell: (params) => (
        <Typography>
          {dayjs(params.value).format("DD/MM/YYYY HH:mm")}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) =>
        params.row.incidenceExists === 0 && (
          <strong>
            <Tooltip title="Crear Incidencia">
              <span>
                <IconButton
                  size="small"
                  disabled={!canCreateIncidence}
                  onClick={() => onEditIncidencesRow(params)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </strong>
        ),
    },
  ];

  function formatDataForMySQL(date) {
    let day = String(date.date()).padStart(2, "0");
    let month = String(date.month() + 1).padStart(2, "0");
    let year = date.year();
    return `${year}-${month}-${day}`;
  }

  const descargaPDF = () => {
    let startDate = formData.startDate;
    let endDate = formData.endDate;

    if (startDate == null && endDate == null) {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      startDate = thirtyDaysAgo;
      endDate = new Date();
    } else {
      startDate = formatDataForMySQL(startDate);
      endDate = formatDataForMySQL(endDate);
    }

    axios
      .get(CALL_USER_DATA)
      .then((response) => {
        let user = { id: response.data.id, name: response.data.name };

        axios.get(CALL_SERVICES).then((services) => {
          let service = services.data.filter(
            (s) => s.id === response.data.serviceId
          )[0];

          let format = { id: 1, name: "PDF" };
          let params = { user, service, startDate, endDate, format };

          axios
            .post(CALL_REPORT, params, { responseType: "blob" })
            .then((response) => {
              let blob = new Blob([response.data], { type: "application/pdf" });
              let url = URL.createObjectURL(blob);
              let link = document.createElement("a");
              link.href = url;
              link.download = "report.pdf";
              link.click();
              URL.revokeObjectURL(url);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((error) => {});
  };

  function aplicaFiltros() {
    getSignings();
  }

  function getSignings() {
    axios
      .get(CALL_USER_SIGNINGS, {
        params: formData,
      })
      .then((response) => {
        response.data.forEach((element, i) => {
          element.actionId === 1
            ? (element.actionId = "Entrada")
            : (element.actionId = "Salida");
        });

        setElemlist(response.data);
        getElemList();
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const onCloseModal = (success) => {
    setModalOpen(false);
    let isSuccess = !(typeof(success) !== "boolean" || !success)
    let message  = isSuccess ? "Incidencia creada correctamente!" : "No se ha registrado ninguna nueva incidencencia!";
    let severity = isSuccess ? "success" : "error";
    setSnackbarObject({message: message, severity: severity});
    setSnackbarOpen(true);
    setRefresh(!refresh);  
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <Container sx={{ height: "70vh" }}>
      <Grid container spacing={1} sx={{ marginBottom: "1rem" }}>
        <Grid item sm={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha Inicial"
              id="datepicker1"
              onChange={handleInitialDate}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha Final"
              id="datepicker2"
              minDate={formData.startDate}
              onChange={handleFinalDate}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={1}>
          <Tooltip title="Aplicar filtros">
            <Button
              sx={{
                backgroundColor: "#05000D",
                color: "white",
                "&:hover": {
                  backgroundColor: "#16111E",
                },
                height: "100%",
              }}
              onClick={aplicaFiltros}
            >
              <SearchIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item sm={12} md={3} sx={{ textAlign: "right" }}>
          {canPdfFichajes && (
          <Tooltip title="Descarga PDF">
            <Button
              sx={{
                backgroundColor: "#05000D",
                color: "white",
                "&:hover": {
                  backgroundColor: "#16111E",
                },
                height: "100%",
              }}
              onClick={descargaPDF}
            >
              <PictureAsPdfIcon />
            </Button>
          </Tooltip>
          )}
        </Grid>
      </Grid>
      {display && (
        <StyledDataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={elemlist}
          columns={columns}
          slots={{
            pagination: CustomPagination,
          }}
        ></StyledDataGrid>
      )}
      {modalOpen && (
        <CreateIncidentWindow data={selectedData} onClose={onCloseModal} />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        disableWindowBlurListener
      >
        <Alert
          severity={snackbarObject.severity}
          sx={{ width: "100%" }}
        >
          {snackbarObject.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default MySigningsDashboard;
