import { Container, Typography, Button, Grid, Tooltip, IconButton, Snackbar, Alert } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import axios from "axios";
import moment from "moment";

import { GridToolbar, esES } from "@mui/x-data-grid";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";
import AddCardIcon from '@mui/icons-material/AddCard';
import ManageCardWindow from "./ManageCardWindow";
import EditIcon from "@mui/icons-material/Edit";
import StyledDataGrid from "./StyledDataGrid";
import CustomPagination from "../CustomPagination";
import { CALL_CARDS } from "../config/routes/Routes";

function CardManagementDashboard(){
    const [elemlist, setElemlist] = useState([]);
    const [rows, setRows] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [reFetchCards, setRefetchCards] = useState(true);
    const [selectedData, setSelectedData] = useState(null);
    const [addEditCardOpen, setAddEditCardOpen] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});
    const [modalOpen, setModalOpen] = useState(false);

    const userInfo = useAuth();
    const userPrivileges = userInfo.privileges;
    const isCreateEnabled = userPrivileges.includes(Privileges.DASH_FICH);
    const isEditEnabled = userPrivileges.includes(Privileges.DASH_FICH);
    const canEnableDisable = userPrivileges.includes(Privileges.DASH_FICH);

    moment().locale("es-ES");

    useEffect(() => {
        getCards();
    }, [reFetchCards]);

    function getCards() {
        axios.get(CALL_CARDS)
        .then((response) => {
            getElemList(response.data);
        })
        .catch((error) => {
            error.handleGlobally && error.handleGlobally();
        })
    }

    const getElemList = (data) => {
        const updatedRows = data.map(e => ({
            id: e.id,
            value: e.value,
            userName: e.User.name + ' ' + e.User.surnames,
            statusId: e.statusId
        }));
        setElemlist(updatedRows);
    }

    function onAddNewCard() {
        setSelectedData({
            id: -1,
            value: "",
            userId: -1,
            userName: "",
            statusId: 1,
        });
        setIsEditing(!isEditing);
        setAddEditCardOpen(true);
    }

    const onExitAddCardEdit = (success) => {
        setRefetchCards(!reFetchCards);
        setIsEditing(!isEditing);
        setAddEditCardOpen(false);
        handleSnackbarOpen(success);
    };

    const onCloseModal = (success) => {
        setRefetchCards(!reFetchCards);
        setIsEditing(!isEditing);
        setModalOpen(false);
        handleSnackbarOpen(success);
    };

    const handleSnackbarOpen = (success) => {
        let isSuccess = !(typeof(success) !== "boolean" || !success)
        let message  = isSuccess ? "Datos modificados correctamente!" : "No se han registrado cambios!"
        let severity = isSuccess ? "success" : "error";
        setSnackbarObject({message: message, severity: severity});
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    async function onEditCard(params) {
        setSelectedData({
            id: params.row.id,
            value: params.row.value,
            userId: params.row.userId,
            userName: params.row.userName,
            statusId: params.row.statusId
        });
        setIsEditing(true);
        setAddEditCardOpen(true);
    }


    const columns = [
        { field: "value", headerName: "Tarjeta", flex: 1},
        { field: "userName", headerName: "Nombre", flex: 1},
        { field: "statusId", align:'center', flex: 0.5,
          headerName: "Estado",
          headerAlign: "center",
          renderCell: (params) => (
            <strong>
            {params.row.statusId === 2 ? 
                <CreditCardOffIcon style={{ color: '#a0191a'}}/> :
                <CreditCardIcon style={{ color: 'green'}}/>
            }
            </strong>
            
            )  },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 0.5,
            renderCell: (params) => (
                <strong>
                    <Tooltip title="Editar tarjeta">
                        <span>
                            <IconButton
                                size="small"
                                disabled={isEditEnabled ? (isEditing ? true : false) : true}
                                onClick={() => onEditCard(params)}
                            >
                                <EditIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </strong>
            )
        }
    ]

    return (
        <Container sx={{height: "70vh"}}>
            <Grid container spacing={2}>
                <Grid item md={11}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontFamily: "Rubik",
                            fontWeight: "600",
                            paddingBottom: "10px"
                        }}
                    >
                        Tarjetas
                        </Typography>    
                </Grid>
                <Grid item md={1} sx={{ textAlign: "right" }}>
                    {isCreateEnabled && (
                        <Tooltip title="Crear tarjeta">
                            <Button
                                sx={{
                                    fontFamily: "Rubik",
                                    marginBottom: "1rem",
                                    backgroundColor: "#05000D",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#16111E"
                                    },
                                }}
                                onClick={onAddNewCard}
                            >
                                <AddCardIcon />
                        </Button>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>

            <StyledDataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={elemlist}
                columns={columns}
                components={{
                    Toolbar: GridToolbar
                }}
                slots={{
                    pagination: CustomPagination
                }}
            ></StyledDataGrid>

            {addEditCardOpen && (
                <ManageCardWindow 
                    editing={addEditCardOpen}
                    data={selectedData}
                    onClose={onExitAddCardEdit}
                />
            )}
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={3000} 
                onClose={handleSnackbarClose} 
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                disableWindowBlurListener
            >
                <Alert 
                    severity={snackbarObject.severity}
                    sx={{ width: '100%' }}
                    >
                    {snackbarObject.message} 
                </Alert>
            </Snackbar>
        </Container>    
    )
}

export default CardManagementDashboard;