import { useState, useEffect } from "react";
import { Typography, Grid, Container } from "@mui/material";

function CurrentDateContainer() {
  const [date, setDate] = useState(new Date());

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Container fixed className="bg-transparent border-2 rounded-lg" sx={{color: "black", borderColor:"rgba(160, 25, 26, 1)"}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" color={"rgba(160, 25, 26, 1)"} className="pt-2" sx={{fontFamily:"Rubik", fontWeight: "600"}}>
            {capitalize(date.toLocaleString("es-ES", { weekday: "long" }))},{" "}
            {date.getDate()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" className="pb-2" sx={{fontFamily:"Rubik", fontWeight: "600"}}>
            {capitalize(date.toLocaleString("es-ES", { month: "long" }))}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
export default CurrentDateContainer;
