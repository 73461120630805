import {
  Tooltip,
  Container,
  Typography,
  IconButton,
  Button,
  Grid,
  Snackbar,
  Alert
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import moment from "moment";
import BackHandIcon from "@mui/icons-material/BackHand";
import ManageRoleWindow from "./ManageRoleWindow";
import { GridToolbar, esES } from "@mui/x-data-grid";
import { CALL_ROLES, CALL_ROLE_PRIVILEGES } from "../config/routes/Routes";
import CustomPagination from "../CustomPagination";
import StyledDataGrid from "./StyledDataGrid";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";

function RoleManagerDashboard() {
  const [elemlist, setElemlist] = useState([]);
  const [rows, setRows] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [reFetchRoles, setRefetchRoles] = useState(true);
  const [addEditRoleOpen, setAddEditRoleOpen] = useState(false);
  const [deleteRole, setDeleteRole] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false); 
  const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});

  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const isCreateEnabled = userPrivileges.includes(Privileges.G_ROL_ADD);
  const isEditEnabled = userPrivileges.includes(Privileges.G_ROL_UPD);
  const isDeleteEnabled = userPrivileges.includes(Privileges.G_ROL_DEL);
  moment().locale("es-ES");

  useEffect(() => {
    getRoles();
  }, [reFetchRoles]);

  function getRoles() {
    axios
      .get(CALL_ROLES)
      .then((response) => {
        setElemlist(response.data);
        getElemList();
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const getElemList = () => {
    elemlist.map((e, i) => {
      setRows(
        rows.push({
          id: e.id,
          name: e.name,
        })
      );
    });
  };

  async function getRolePrivileges(roleId) {
    let privileges;
    await axios
      .get(CALL_ROLE_PRIVILEGES, { params: { roleId: roleId } })
      .then((response) => {
        privileges = response.data;
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
    return privileges;
  }

  function onAddNewRole() {
    setSelectedData({
      id: "",
      name: "",
      privileges: [],
    });
    setIsEditing(true);
    setAddEditRoleOpen(true);
  }

  async function onEditRole(params) {
    let privileges = await getRolePrivileges(params.row.id);
    setSelectedData({
      id: params.row.id,
      name: params.row.name,
      privileges: privileges,
    });
    setIsEditing(true);
    setAddEditRoleOpen(true);
  }

  function onDeleteRole(params) {
    setSelectedData({
      id: params.row.id,
      name: params.row.name,
      privileges: [],
    });
    setIsEditing(true);
    setDeleteRole(true);
  }

  const onExitRoleAddEdit = (success) => {
    setRefetchRoles(!reFetchRoles);
    setIsEditing(false);
    setAddEditRoleOpen(false);
    handleSnackbarOpen(success);
  }

  function onExitDelete(success) {
    setRefetchRoles(!reFetchRoles);
    setIsEditing(false);
    setDeleteRole(false);
    handleSnackbarOpen(success);
  }

  const handleSnackbarOpen = (success) => {
    let isSuccess = !(typeof(success) !== "boolean" || !success)
    let message  = isSuccess ? "Datos modificados correctamente!" : "No se ha registrado ningun cambio!"
    let severity = isSuccess ? "success" : "error";
    setSnackbarObject({message: message, severity: severity});
    setSnackbarOpen(true);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };


  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    { field: "name", headerName: "Nombre", flex: 1 },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 0.5,
      renderCell: (params) => (
        <strong>
          <Tooltip title="Editar rol">
            <span>
              <IconButton
                size="small"
                disabled={isEditEnabled ? (isEditing ? true : false) : true}
                onClick={() => onEditRole(params)}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Eliminar rol">
            <span>
              <IconButton
                size="small"
                disabled={isDeleteEnabled ? (isEditing ? true : false) : true}
                onClick={() => onDeleteRole(params)}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </strong>
      ),
    },
  ];

  return (
    <Container sx={{ height: "70vh" }}>
      <Grid container spacing={2}>
        <Grid item md={11}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Rubik",
              fontWeight: "600",
              paddingBottom: "10px",
            }}
          >
            Roles
          </Typography>
        </Grid>
        <Grid item md={1} sx={{ textAlign: "right" }}>
          {isCreateEnabled && (
            <Tooltip title="Crear rol">
              <Button
                sx={{
                  fontFamily: "Rubik",
                  marginBottom: "1rem",
                  backgroundColor: "#05000D",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#16111E",
                  },
                }}
                onClick={onAddNewRole}
              >
                <BackHandIcon />
                <Typography sx={{ fontWeight: "600" }}>+</Typography>
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <StyledDataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        rows={elemlist}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
        }}
        slots={{
          pagination: CustomPagination,
        }}
      ></StyledDataGrid>

      {(addEditRoleOpen || deleteRole) && (
        <ManageRoleWindow
          editing={addEditRoleOpen}
          deleting={deleteRole}
          data={selectedData}
          onClose={deleteRole ? onExitDelete : onExitRoleAddEdit}
        />
      )}
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={3000} 
        onClose={handleSnackbarClose} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        disableWindowBlurListener
      >
        <Alert 
          severity={snackbarObject.severity}
          sx={{ width: '100%' }}
        >
         {snackbarObject.message} 
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default RoleManagerDashboard;
