import {
  Button,
  Container,
  TextField,
  IconButton,
  Typography,
  Tooltip,
  Snackbar,
  Grid,
  Alert,
} from "@mui/material";
import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Theme, styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import {
  DataGrid,
  esES,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import CreateServiceWindow from "./CreateServiceWindow";
import {
  CALL_EDITSERVICE,
  CALL_SERVICES,
  CALL_DELETESERVICE,
} from "../config/routes/Routes";
import StyledDataGrid from "./StyledDataGrid";
import CustomPagination from "../CustomPagination";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";

function ServiceManagementDashboard() {
  const [elemlist, setElemlist] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const dataGridRef = useRef(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarObject, setSnackbarObject] = useState({message: "", severity: "info"});

  //Variables para el modal
  const [modalOpen, setModalOpen] = useState(false);

  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const isCreateEnabled = userPrivileges.includes(Privileges.G_DEP_ADD);
  const isDeleteEnabled = userPrivileges.includes(Privileges.G_DEP_DEL);

  function onDeleteServiceRow(params) {
    axios
      .delete(CALL_DELETESERVICE, { data: params.row })
      .then((response) => {
        handleSnackbarOpen(true);
       setRefresh(!refresh);
      })
      .catch((error) => {
        handleSnackbarOpen(false);
        error.handleGlobally && error.handleGlobally();
      });
  }

  const columns = [
    { field: "id", headerName: "ID", editable: false, flex: 1 },
    { field: "name", headerName: "Nombre", editable: true, flex: 3 },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Tooltip title="Borrar Departamento">
            <span>
              <IconButton
                size="small"
                disabled={isDeleteEnabled ? false : true}
                onClick={() => onDeleteServiceRow(params)}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </strong>
      ),
    },
  ];

  useEffect(() => {
    getServices();
  }, [refresh]);

  function getServices() {
    axios
      .get(CALL_SERVICES)
      .then((response) => {
        setElemlist(response.data);
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const handleProcessRowUpdate = React.useCallback(async (newValues, prevValues) => {
    if (newValues.name === prevValues.name) {
      handleSnackbarOpen(false);
      return newValues;
    }
    
    let formData = { serviceId: newValues.id, serviceName: newValues.name };
    axios
      .post(CALL_EDITSERVICE, formData)
      .then((response) => {
        setRefresh(!refresh);
        handleSnackbarOpen(true);
      })
      .catch((error) => {
        handleSnackbarOpen(false);
        error.handleGlobally && error.handleGlobally();
      });
    return newValues;
  });

  const handleProcessRowUpdateError = React.useCallback((error) => {
    handleSnackbarOpen(false);
  }, []);

  const handleAddService = () => {
    setModalOpen(true);
  };

  const onCloseModal = (success) => {
    setModalOpen(false);
    handleSnackbarOpen(success);
    setRefresh(!refresh);
  };

  const handleSnackbarOpen = (success) => {
    let isSuccess = !(typeof(success) !== "boolean" || !success)
    let message  = isSuccess ? "Departamento modificado correctamente!" : "No se ha registrado ningún cambio!"
    let severity = isSuccess ? "success" : "error";
    setSnackbarObject({message: message, severity: severity});
    setSnackbarOpen(true);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <Container sx={{ height: "70vh" }}>
      <Grid container spacing={2}>
        <Grid item md={9}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Rubik",
              fontWeight: "600",
              paddingBottom: "10px",
            }}
          >
            Gestión de Departamentos
          </Typography>
        </Grid>
        <Grid item md={3} sx={{ textAlign: "right" }}>
          {isCreateEnabled && (
            <Tooltip title="Crear Departamento">
              <Button
                onClick={handleAddService}
                sx={{
                  fontFamily: "Rubik",
                  marginBottom: "1rem",
                  backgroundColor: "#05000D",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#16111E",
                  },
                }}
              >
                <Diversity3Icon />
                <Typography sx={{ fontWeight: "600" }}>+</Typography>
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <StyledDataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        rows={elemlist}
        columns={columns}
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        ref={dataGridRef}
        slots={{
          pagination: CustomPagination,
        }}
      ></StyledDataGrid>

      {modalOpen && <CreateServiceWindow onClose={onCloseModal} />}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        disableWindowBlurListener
      >
        <Alert
          severity={snackbarObject.severity}
          sx={{ width: "100%" }}
        >
          {snackbarObject.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ServiceManagementDashboard;
